.formBlock {
  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

.logoImage {
  text-align: center;
  width: 100%;
}

.logoImage img {
  width: 100%;
  max-width: 200px;
}

.forgotText {
  text-align: center;
  /* margin-top: 15px; */
}

.forgotText h3 {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 49px;
}

.forgotText p {
  color: #6b6b6b;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  padding: 0px 20px;
}

.buttonControls {
  display: flex;
  margin-top: 33px;
}

.leftButton {
  font-weight: 600;
  font-size: 18px;
  color: #575656;
  border-radius: 8px;
  background: #ffffff;
  border: unset;
  padding: 0px 10px;
  width: 100%;
  height: 56px;
  border: 1px solid #cdcdcd;
}

.rightButton {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  line-height: 21.78px;
  background: #0f7dff;
  border-radius: 8px;
  border: unset;
  padding: 0px 10px;
  margin-left: 20px;
  width: 100%;
  height: 56px;
}

@media only screen and (max-width: 575px) {
  .forgotText h3 {
    font-size: 28px;
    line-height: 38px;
  }

  .forgotText p {
    font-size: 16px;
    line-height: 23px;
    padding: 0px 0px;
  }

  .leftButton {
    height: 45px;
  }

  .rightButton {
    margin-left: 10px;
    height: 45px;
  }
}
