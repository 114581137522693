.profileSection h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36.31px;
  color: #000000;
}

.profileSection p {
  font-weight: 500;
  font-size: 15px;
  line-height: 18.15px;
  color: #828282;
}

.profileBox {
  box-shadow: 0px 0px 4px 0px #00000026;
  padding: 20px;
}

.profileSettingsSection {
  padding: 20px 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.profileHeading {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
}

.userInfo {
  color: #0f7dff;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  padding: 12px 0px;
}

.displayOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

.displayOption p {
  color: #333333;
  line-height: 18.15px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  padding: 20px 0px;
}

.displayOption svg {
  font-size: 20px;
  color: #333333;
}

.displayOptionBorder {
  border-bottom: unset !important;
}

.displayOptionBorder p {
  cursor: pointer;
}

.updateBtnSpace {
  margin-top: 28px;
}

/* edit profile css */

.uploadProfile {
  text-align: center;
}

.profileImg {
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
}

.profileImg img {
  width: 130px;
  max-width: initial;
  height: 130px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
}

.uploadPic {
  background: #ffffff;
  border-radius: 44px;
  width: 38px;
  height: 38px;
  line-height: 32px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #00000026;
}

.uploadPic svg {
  font-size: 20px;
  color: #0f7dff;
}

.profileInput {
  bottom: 40px;
  margin: 0 auto;
  position: relative;
  left: 45px;
  text-align: center;
}

.userDetails h2 {
  font-size: 20px;
  color: #202020;
  font-weight: 700;
  line-height: 24.2px;
  word-break: break-word;
}

.userDetails h4 {
  color: #626262;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
  word-break: break-all;
}

.userDetails p {
  color: #0f7dff;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .profileSettingsSection {
    margin-bottom: 30px;
  }
}

.updateBtnSpace button {
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  height: 56px;
  width: 100%;
  border-radius: var(--bs-border-radius);
}

/* edit profile css */

.uploadSection {
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #00000040;
}
.uploadProfile {
  padding: 20px 20px;
  text-align: center;
}

/* .profileImg {
  border: 2px solid #dcdcdc;
  border-radius: 50%;
  display: inline-block;
  width: 134px;
  height: 134px;
}

.profileImg img {
  width: 130px;
  max-width: initial;
  height: 130px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
  padding: 2px;
} */

.uploadPic {
  background: #ffffff;
  border-radius: 44px;
  width: 38px;
  height: 38px;
  line-height: 32px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #00000026;
}

.uploadPic svg {
  font-size: 20px;
  color: #0094f6;
  margin-top: 8px;
}

.profileInput {
  bottom: 40px;
  margin: 0 auto;
  position: relative;
  left: 45px;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .profileSectionRow {
    display: flex;
    flex-direction: column-reverse;
  }
}
/* -----support */
.supportText {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #626262;
  margin-bottom: 50px;
  margin-top: 20px;
}

.formgropupmb {
  margin-bottom: 130px !important;
}

/* ---about */

/* about css */
.aboutPage h2 {
  font-weight: 400;
  font-size: 30px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 82px;
}

.aboutBox {
  background: #ffffff;
  display: flex;
  border-radius: 16px;
}

.aboutBackground {
  background: #ffffff;
}

.aboutBox .aboutImage {
  width: 100%;
}

.aboutBox .aboutImage img {
  width: 100%;
  border-radius: 50px 0px 50px 0px;
}

.aboutCategories {
  background: #0f7dff05;
  padding: 30px 0px 40px;
}

.aboutPage h5 {
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 30px;
  text-align: center;
  margin-top: 30px;
}

.aboutWorks {
  padding: 40px 0px 0px;
}

.worksImg {
  width: 100%;
}

.worksImg img {
  width: 100%;
}

.worksImg h6 {
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 21.78px;
  display: flex;
  flex-wrap: wrap;
}

.worksImg h6 span {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.worksImg p {
  color: #333333;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  width: 80%;
}

/* chat feture */
.chatFeature {
  text-align: end;
  padding-bottom: 30px;
  cursor: pointer;
}
.chatFeature img {
  margin-right: 30px;
}

.bgColor {
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.bgColor svg {
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #00000033;
}
.bgColor .bgColorRight {
  margin-right: 20px;
}

.bgColor .bgColorLeft {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.bgColor .bgColorLeft h2 {
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  margin: 0px;
}

.bgColor .bgColorLeft p {
  font-size: 15px;
  font-weight: 500;
  color: #828282;
  margin-bottom: 0px;
}

@media only screen and (max-width: 575px) {
  .worksImg p {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .bgColor .bgColorLeft h2 {
    font-size: 24px;
  }

  .bgColor .bgColorLeft p {
    font-size: 14px;
  }

  .aboutPage h5 {
    font-size: 24px;
  }
}

.scrollOverlay {
  height: 78vh;
  overflow-y: scroll;
}

/* -----support--- */
.supportDriverBtn {
  width: 100%;
  text-align: center;
}

.supportDriverBtn button {
  border: 1px solid #2fc2fe;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #0094f6;
  background: #2fc2fe1a;
  border-radius: 45px;
  height: 50px;
  width: 80%;
  text-align: center;
}

.downiconicon {
  right: 13px;
  top: 34px;
  font-size: 30px;
  position: absolute;
  cursor: pointer;
  color: #333333;
}
.topHeadAboutUs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 20px;
}
.topHeadBackBtn {
  padding: 12px 0px;
}
.topHeadBackBtn svg {
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #00000033;
}

@media screen and (max-width: 575px) {
  .topHeadAboutUs {
    padding: 0px 0px 0px 0px;
  }
}
