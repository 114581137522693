.formBlock {
  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}
.logoImage {
  text-align: center;
  width: 100%;
}
.loginBtn button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  height: 50px;
  width: 100%;
  border-radius: 1px;
  margin-top: 15px;
  border-radius: var(--bs-border-radius);
}
.guestBackBtn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.36px;
  color: #0f7dff;
  cursor: pointer;
}
