.topHeadSummmary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(297.65deg, #2fc2fe 14.83%, #0094f6 83.99%);
  color: white;
  padding: 0px 90px 0px 90px;
}
.topHeadBackBtn {
  padding: 12px 0px;
}
.topHeadBackBtn svg {
  background-color: white;
  color: black;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
}
.topTitle {
  font-weight: 600;
  font-size: 20px;
}
.topMainContainer {
  margin-top: 22px;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}
.dateRangeField {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-right: 7%;
  margin-top: 12px;
}
.applyBtnSection {
  display: flex;
  margin-bottom: -12px;
  gap: 6px;
}

.applyBtnSection button {
  font-weight: 500;
  background-color: #0094f6;
}

.infoAvailabilty {
  display: flex;
  justify-content: space-between;
}
.availabilityTitle {
  font-size: 12px;
  font-weight: 500;
}
.availabilityValue {
  font-size: 10px;
  font-weight: 400;
  color: #4770eb;
}
.helperValue {
  color: #ec3b1e;
  font-size: 10px;
  font-weight: 400;
}

.sourceContainer {
  display: flex;
  gap: 6px;
  align-items: center;
}
.sourceName {
  font-size: 12px;
  font-weight: 600;
}
.sourceValue {
  font-size: 10px;
  font-weight: 400;
  color: #555555;
  margin-left: 30px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowed text */
  text-overflow: ellipsis;
}
.hrSection {
  margin: 8px 0px 8px 0px;
}
.infoCategory {
  display: flex;
  justify-content: space-between;
}
.categoryName {
  font-size: 12px;
  font-weight: 600;
  color: #0f7dff;
}
.infoItemVan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.VehicleTitle {
  font-size: 11px;
  font-weight: 500;
}
.vehicleValue {
  color: #0f7dff;
  font-size: 11px;
  font-weight: 500;
}
.pendingText {
  color: #fd9700;
}
.successText {
  color: #058321;
}
.jobStatus {
  font-size: 12px;
  font-weight: 500;
  color: #058321;
  background-color: #e5ffeb;
  border: 1px solid #058321;
  border-radius: 4px;
  padding: 2px 8px;
}
.paymentSection {
  display: flex;
  align-items: center;
  gap: 3px;
}
.jobAmountSection {
  display: flex;
  gap: 6px;
  align-items: center;
}
.jobAmount {
  color: #0094f6;
  font-size: 12px;
  font-weight: 600;
}
.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}
.chatFeature {
  text-align: end;
  margin-right: 24px;
  margin-bottom: 12px;
  cursor: pointer;
}
.chatFeature img {
  height: 40px;
  width: 40px;
}
.loadMoreSection {
  display: flex;
  justify-content: center;
}
.loadMoreBtn {
  background: #2fc2fe;
  color: white;
  border-radius: 8px;
  padding: 8px 22px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.totalEarnSetion {
  display: flex;
  justify-content: flex-end;
}
.earnTitle {
  font-size: 16px;
  font-weight: 600;
  color: #626262;
}
.earnValue {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.selectDate {
  font-weight: 600;
}
.jobStatusCancel {
  font-size: 12px;
  font-weight: 500;
  color: #ec3b1e;
  background-color: #ffe5e0;
  border: 1px solid #ec3b1e;
  border-radius: 4px;
  padding: 2px 8px;
}
.subTitleName {
  font-weight: 500;
  font-size: 15px;
  color: #828282;
}
.titleDescription {
  font-weight: 600;
  font-size: 18px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.viaShow {
  float: right;
}
.stopTitle {
  font-size: 12px;
  font-weight: 500;
}
.stopValue {
  color: #0094f6;
  font-size: 12px;
  font-weight: 500;
}
.addressRouteWrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.subLocationInner {
  display: flex;
  gap: 12px;
}
.subLocationContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.leftRouteLine {
  border-left: 1px dashed #a5a5a5;
  width: 2px;
  height: 100%;
}
.routeLinePath {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .topHeadSummmary {
    padding: 0px 30px 0px 30px;
  }
  .dateRangeField {
    margin-left: 7%;
  }
}
