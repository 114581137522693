.floorSubmitBtn {
  width: 100%;
  padding: 10px;
}

.activeFloor {
  /* color: #0f7dff; */
  color: white;
  background: #0f7dff;
  width: 100%;
  text-align: center;
  height: 40px;
  border-radius: 4px;
}

.selectedBtn {
  width: 100px;
  height: 38px;
}

@media screen and (max-width: 400px) {
  .selectedBtn {
    width: 70px;
    height: 32px;
    font-size: 12px;
  }
}

.optionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid #e1e1e1;
  border-radius: 4px;
  padding: 6px;
  background-color: white;
  cursor: pointer;
  height: 45px;
}
.optionName {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}
.optionCounts {
  display: flex;
  gap: 8px;
  align-items: center;
}
.optionCounting {
  padding: 4px 6px;
  background-color: #efefef;
  border-radius: 50px;
}

.optionCountQuantity {
  display: flex;
  gap: 20px;
  align-items: center;
}
.optionCounts svg {
  cursor: pointer;
}
.optionQuantity {
  font-weight: 600;
}
.optionQuantitySecond {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 84px;
}
.quantityLable {
  color: #808080;
  font-weight: 500;
  font-size: 13px;
}

.couponTopField {
  align-items: center;
  margin-bottom: 12px;
}

.couponTopField button {
  font-weight: 600;
}

@media screen and (max-width: 570px) {
  .couponTopField button {
    width: unset;
    float: right;
  }
}
@media screen and (max-width: 470px) {
  .couponTopField button {
    font-size: 12px;
  }
}

.couponContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #d3d3d3;
  padding: 12px;
  background-color: white;
  cursor: pointer;
}
.couponInfo {
  display: flex;
  gap: 12px;
}
.couponInfo img {
  height: 35px;
  margin-top: 6px;
}
.couponApply button {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 14px;
}
.offValue {
  font-size: 16px;
  font-weight: 600;
}
.couponTitle {
  color: #828282;
  font-size: 12px;
  font-weight: 600;
}
.couponValid {
  color: #626262;
  font-size: 12px;
  font-weight: 500;
}
