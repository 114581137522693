.signPageCls span {
  display: flex;
  justify-content: end;
  /* margin: 20px 0px 10px; */
}

/* .signupPage {
  margin: 0px auto;
  max-width: 485px;
  padding: 0px 20px;
  margin-bottom: 30px;
} */

.signUpPara {
  line-height: 30px;
  font-size: 16px;
  color: #626262;
  font-weight: 500;
  text-align: center;
}

.nextButton {
  margin-top: 35px;
}

.bottomText {
  text-align: center;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  margin-top: 10px;
  margin-bottom: 0px !important;
}

.bottomText a {
  text-decoration: none !important;
  color: #0094f6;
  font-weight: 600;
}

.signPageArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.signPageArrow svg {
  box-shadow: 0px 0px 4px 0px #00000033;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  position: relative;
  right: 40px;
  cursor: pointer;
}

.downiconicon {
  right: 13px;
  top: 34px;
  font-size: 30px;
  position: absolute;
  cursor: pointer;
  color: #333333;
}

.uploadSection {
  margin: 20px 0px;
}

/* .uploadSectionSpace {
  margin: 0px 0px !important;
} */

.uploadSection h4 {
  color: #0094f6;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
}

.uploadImage {
  border: 1px dotted #6cadd3;
  background: #fafafa;
  width: 100%;
  max-width: 315px;
  height: 200px;
  margin: 0px auto;
  position: relative;
}

.cancelIcon svg {
  color: #ec3b1e;
  width: 24px;
  height: 33px;
  position: absolute;
  right: -8px;
  top: -17px;
  cursor: pointer;
  z-index: 99 !important;
}

.uploadInputImg {
  width: 100%;
  text-align: center;
}

.uploadInputImg img {
  border: none;
  object-fit: contain;
  position: relative;
  width: 100%;
  max-width: 315px;
  height: 200px;
  padding: 14px;
}

.uploadInput {
  text-align: center;
  top: 46px;
  position: absolute;
  left: 0;
  right: 0;
}

.uploadInput p {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #777777;
  text-align: center;
}

.uploadbutton {
  background: linear-gradient(303.02deg, #2fc2fe 29.51%, #0094f6 81.02%);
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 8px 30px;
}

.uploadbutton svg {
  font-size: 17px !important;
  color: #ffffff !important;
  margin-right: 3px !important;
  margin-top: -4px;
}

.uploadIcon {
  text-align: center;
  position: absolute;
  bottom: 30px;
  text-align: center;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 379px) {
  .signPageArrow svg {
    right: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signPageArrow svg {
    right: 8px;
  }
}

/* vehicle color modal css */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader h4 {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
}

.modalHeader svg {
  color: #0094f6;
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.inpuColorBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* vehicle type modal css */
.vehicleInputDisplay {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.vehicleInputDisplay .vehicleInputText h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #202020;
  text-transform: capitalize;
}

.vehicleInputDisplay .vehicleInputText p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
}

.vehicleInputDisplay img {
  width: 80px;
  /* height: 52px; */
  margin-left: 20px;
}

@media only screen and (max-width: 400px) {
  .vehicleInputDisplay .vehicleInputText h2 {
    font-size: 18px;
    line-height: 18px;
  }

  .vehicleInputDisplay .vehicleInputText p {
    font-size: 12px;
  }
}

.signUpArrow {
  display: flex;
  justify-content: center;
}

.signUpArrow svg {
  box-shadow: 0px 0px 4px 0px #00000033;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  position: relative;
  right: 8px;
  top: -5px;
  cursor: pointer;
}

@media only screen and (min-width: 576px) and (max-width: 1246px) {
  .signUpArrow svg {
    right: 46px;
  }
}

.rightSectionImg {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .rightSectionImg {
    height: 100vh;
  }
}

.uploadImages {
  border: 1px dotted #6cadd3;
  background: #e3e3e3;
  width: 100%;
  max-width: 354px;
  height: 200px;
  margin: 0px auto;
  position: relative;
}
.eyeIcon svg {
  box-shadow: 0px 0px 4px 0px #00000040;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 6px;
  margin: 4px;
  position: absolute;
  background: #ffffff;
  cursor: pointer;
  top: 0;
}

.formBlocks {
  /* border-radius: 50px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  width: 100%;
  max-width: 820px;
  margin: 0px auto;
  padding: 5px 20px; */

  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

.activeCls {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  color: #058321;
  margin-top: 10px;
}

.inActiveCls {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  color: #ec3b1e;
  margin-top: 10px;
}

.userDetailsDate input {
  height: 45px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  background-size: auto !important;
  border-radius: 4px !important;
  color: #757575 !important;
  background-repeat: no-repeat !important;
  background-position: 90% !important;
  border: 0.8px solid #d0d0d0 !important;
  z-index: 1 !important;
}
.dateText {
  text-align: center;
}
.dateText span {
  text-align: center;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}

.dateText p {
  color: #0094f6;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
}

.saveButton {
  text-align: center;
}
.saveButton button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(297.65deg, #2fc2fe 14.83%, #0094f6 83.99%);
  height: 50px !important;
  width: 100%;
  max-width: 150px;
  margin: 0px auto;
  border-radius: var(--bs-border-radius);
}

.dateHeading {
  color: #0094f6;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
}

.documentExpirationDate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
}
.documentExpirationDate span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  color: #333333;
}
.documentExpirationDate p {
  margin-bottom: 0px;
}

.documentExpirationDate h4 {
  color: #0094f6;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
}

.documentExpirationDate hr {
  border-top: 1px solid #c5a9a9;
  width: 100%;
  margin-top: 20px;
  max-width: 395px;
}

.documentStatus p {
  text-align: center !important;
}

.activeFloor {
  color: white !important;
  background: #2fc2fe !important;
  width: 100%;
  text-align: center;
  height: 40px;
  border-radius: 4px;
}
