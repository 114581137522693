.formBlock {
  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

.logoImage {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.logoImage img {
  /* width: 100%; */
  max-width: 159px;
  /* height: 90px; */
  /* width: 130px; */
}

.loginType {
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.forgotPassword {
  text-align: end;
  margin-top: 5px;
}

.forgotPassword a {
  font-weight: 400;
  font-size: 12px;
  color: #1b1837;
  line-height: 14.52px;
  text-decoration: none !important;
}

.loginBtn button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  height: 50px;
  width: 100%;
  border-radius: 1px;
  margin-top: 15px;
  border-radius: var(--bs-border-radius);
}

.bottomText {
  text-align: center;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  margin-top: 20px;
  margin-bottom: 0px !important;
}

.bottomText a {
  text-decoration: none !important;
  color: #0f7dff;
  font-weight: 700;
}
.orSection {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  font-size: 12px;
  color: grey;
  font-weight: bold;
}
.orSection span {
  display: flex;
  justify-content: center;
  width: 22px;
  height: 2px;
  background-color: grey;
  margin: auto 6px;
}
.guestBackBtn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.36px;
  color: #0f7dff;
  cursor: pointer;
}
.guestCheckoutBtn {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #0f7dff;
  border: 1px solid #0f7dff70;
  /* background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44)); */
  height: 50px;
  width: 100%;
  border-radius: 1px;
  margin-top: 8px;
  border-radius: var(--bs-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
