.bgColor {
  display: flex;
  /* justify-content: start; */
  justify-content: space-between;

  align-items: center;

  /* background-color: rgb(243 248 255); */
  padding: 22px 0px 0px 6%;
}
.bgColor svg {
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #00000033;
}
.bgColor .bgColorRight {
  margin-right: 20px;
}

.bgColor .bgColorLeft {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.bgColor .bgColorLeft h2 {
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  margin: 0px;
}
.cardContainer {
  display: flex;
  padding: 40px 0px 0px 0px;
  margin: 0px 22px;
}
.crossIcon {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.topSection {
  display: flex;
}
.cardWrapper {
  width: 300px;
  border: 1px solid #ccc;
  background-color: #4770eb;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}
.cardBlock {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cardImage img {
  height: 180px;
  width: 340px;
}
.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardScannerImg img {
  width: 38px;
}
.cardDetails {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.cardNumber {
  font-size: 15px;
  font-weight: 500;
  color: #c7d1ee;
}

.cardNumberValue {
  font-size: 15px;
  font-weight: 500;
}

.cardExpiry {
  font-size: 13px;
  font-weight: 500;
  color: #c7d1ee;
}

.cardExpiryValue {
  font-size: 13px;
  font-weight: 500;
}
.addCardSection {
  display: flex;
  justify-content: flex-end;
  margin: 22px 75px 22px 22px;
}
.addCardBtn {
  padding: 8px 60px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(to left, #0f7dff, #6931df);
  cursor: pointer;
}

.addCardBtn span {
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(to right, #6931df, #0f7dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addCardBtn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background-color: white;
  z-index: -1;
  transition: 200ms;
}
.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
}
.notFoundMessage {
  color: grey;
  font-weight: 600;
}
