/* setting sidebar css */
.profileSection h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36.31px;
  color: #000000;
}

.profileSection p {
  font-weight: 500;
  font-size: 15px;
  line-height: 18.15px;
  color: #828282;
}

.profileBox {
  box-shadow: 0px 0px 4px 0px #00000026;
  padding: 20px;
}

.profileSettingsSection {
  padding: 20px 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #00000040;
  overflow: hidden;
}

.profileHeading {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-transform: uppercase;
}

.profileHeading span {
  color: #0094f6;
  text-transform: uppercase;
}

.displayOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

.displayOption p {
  color: #333333;
  line-height: 18.15px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
  padding: 20px 0px;
}

.displayOption svg {
  font-size: 20px;
  color: #333333;
}

.displayOptionBorder {
  border-bottom: unset !important;
}

.displayOptionBorder p {
  cursor: pointer;
}

.updateBtnSpace {
  margin-top: 28px;
}

/* edit profile css */

.uploadSection {
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #00000040;
}
.uploadProfile {
  padding: 20px 20px;
  text-align: center;
}

.profileImg {
  border: 2px solid #dcdcdc;
  border-radius: 50%;
  display: inline-block;
  width: 134px;
  height: 134px;
}

.profileImg img {
  width: 130px;
  max-width: initial;
  height: 130px;
  border-radius: 50%;
  border: none;
  object-fit: contain;
  padding: 2px;
}

.uploadPic {
  background: #ffffff;
  border-radius: 44px;
  width: 38px;
  height: 38px;
  line-height: 32px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #00000026;
}

.uploadPic svg {
  font-size: 20px;
  color: #0094f6;
  margin-top: 8px;
}

.profileInput {
  bottom: 40px;
  margin: 0 auto;
  position: relative;
  left: 45px;
  text-align: center;
}

.userDetails h2 {
  margin-top: 20px;
  font-size: 20px;
  color: #202020;
  font-weight: 700;
  line-height: 24.2px;
  word-wrap: break-word;
}

.userDetails h4 {
  color: #626262;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
  word-wrap: break-word;
}

.userDetails p {
  color: #0094f6;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .profileSettingsSection {
    margin-bottom: 30px;
  }
}

.ratingSection {
  border-top: 1px solid #d9d9d9;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratingSection h6 {
  font-size: 16px;
  font-weight: 600;
}

.ratingSection sub {
  color: #333333;
  font-size: 10px;
  font-weight: 600;
}

.ratingSection svg {
  color: #ebb93a;
  font-size: 18px;
  margin-left: 3px;
}

.vehicleInfo {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #202020;
  margin: 25px 0px 0%;
}

.vanDetails {
  padding: 20px 10px 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #00000040;
  margin-top: 20px;
}

.vanIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vanDetails h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 16px;
}

.vanDetails p {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.vanDetails strong {
  font-size: 10px;
  font-weight: 500;
  color: #0094f6;
  word-break: break-word;
}

.vanDetails span {
  font-size: 10px;
  font-weight: 500;
  color: #333333;
}

.vanDetails img {
  width: 100%;
  max-width: 64px;
  height: 42px;
  object-fit: contain;
}

.vanDetails svg {
  color: #626262;
  font-size: 20px;
  margin-left: 9px;
  cursor: pointer;
}

.vanDelete {
  color: #ec3b1e !important;
}

/* price section api*/
.downiconicon {
  right: 13px;
  top: 34px;
  font-size: 30px;
  position: absolute;
  cursor: pointer;
  color: #333333;
}

.inputFieldClass {
  cursor: pointer;
}

.priceSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 2px 0px #0000001a inset;
  border: 0.3px solid #f2f2f2;
  background: #f9f9f9;
  padding: 17px 18px;
  margin-bottom: 20px;
  gap: 12px;
}

.priceSection label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #333333;
  margin-bottom: 0px;
}

.priceSectionInfo {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #626262;
  padding-top: 8px;
}

.priceSection input {
  border: 0.3px solid #dcdcdc;
  background: #ffffff;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  color: #000000 !important;
  width: 100%;
  max-width: 110px;
  border-radius: 150px;
  height: 31px;
}

.priceSection input:focus {
  box-shadow: unset !important;
  border-color: #dcdcdc !important;
}

.priceSection input::placeholder {
  color: #828282 !important;
}

.priceSection span {
  color: #ff0000;
  font-weight: 400;
  font-size: 20px;
  margin-left: 2px;
}
.fieldWithUnit {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  color: gray;
  min-width: 88px;
}
/* radius page css */
.radiusSection h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
.radiusSection p {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #626262;
  width: 100%;
  max-width: 368px;
  margin: 0px auto;
  margin-bottom: 50px;
}

.radiusSection h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  color: #333333;
  margin-top: 42px;
}

.radiusInput {
  margin-top: 40px;
  margin-bottom: 100px;
}

.radiusInput input {
  border: 0.3px solid #dcdcdc;
  background: #f9f9f9;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  width: 100%;
  border-radius: 150px;
  height: 50px;
  color: #0094f6 !important;
}

.radiusInput input:focus {
  box-shadow: unset !important;
  border-color: #dcdcdc !important;
}

.radiusInput ::placeholder {
  color: #0094f6 !important;
  font-size: 14px;
}

.activeCls {
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  color: #058321 !important;
}

.inActiveCls {
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  color: #ec3b1e !important;
}

@media screen and (max-width: 991px) {
  .drProfileSectionRow {
    display: flex;
    gap: 12px;
    flex-direction: column-reverse;
  }
}
/* -----support--- */
.supportDriverBtn {
  width: 100%;
  text-align: center;
}
.supportDriverBtn button {
  border: 1px solid #2fc2fe;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #0094f6;
  background: #2fc2fe1a;
  border-radius: 45px;
  height: 50px;
  width: 80%;
  text-align: center;
}

/* ----tutorial */
.tutorialHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tutorialHeading h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 36.31px;
}

.supportText {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #626262;
  margin-bottom: 50px;
  margin-top: 20px;
}

/* ---about */

/* about css */
.aboutPage h2 {
  font-weight: 400;
  font-size: 30px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 82px;
}

.aboutBox {
  background: #ffffff;
  display: flex;
  border-radius: 16px;
}

.aboutBackground {
  background: #ffffff;
}

.aboutBox .aboutImage {
  width: 100%;
}

.aboutBox .aboutImage img {
  width: 100%;
  border-radius: 50px 0px 50px 0px;
}

.aboutCategories {
  background: #0f7dff05;
  padding: 30px 0px 40px;
}

.aboutPage h5 {
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 30px;
  text-align: center;
  margin-top: 30px;
}

.aboutWorks {
  padding: 40px 0px 0px;
}

.worksImg {
  width: 100%;
}

.worksImg img {
  width: 100%;
}

.worksImg h6 {
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 21.78px;
  display: flex;
  flex-wrap: wrap;
}

.worksImg h6 span {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.worksImg p {
  color: #333333;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  width: 80%;
}

/* chat feture */
.chatFeature {
  text-align: end;
  padding-bottom: 30px;
  cursor: pointer;
}
.chatFeature img {
  margin-right: 30px;
}

.bgColor {
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.bgColor svg {
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #00000033;
}
.bgColor .bgColorRight {
  margin-right: 20px;
}

.bgColor .bgColorLeft {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.bgColor .bgColorLeft h2 {
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  margin: 0px;
}

.bgColor .bgColorLeft p {
  font-size: 15px;
  font-weight: 500;
  color: #828282;
  margin-bottom: 0px;
}

@media only screen and (max-width: 575px) {
  .worksImg p {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .bgColor .bgColorLeft h2 {
    font-size: 24px;
  }

  .bgColor .bgColorLeft p {
    font-size: 14px;
  }

  .aboutPage h5 {
    font-size: 24px;
  }
}

.scrollOverlay {
  height: 104.5vh;
  overflow-y: scroll;
}

/** Packing section css **/
.packingRadioWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 22px;
}

.packingRadio {
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 18px;
  border-radius: 6px;
  border: 1px solid #efefef;
}

.btnText {
  font-weight: 500;
}

.profileSubText {
  color: #626262;
  font-size: 14px;
  margin-top: 6px;
  word-wrap: break-word;
}
.topHeadAboutUs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 20px;
}
.topHeadBackBtn {
  padding: 12px 0px;
}
.topHeadBackBtn svg {
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #00000033;
}

@media screen and (max-width: 575px) {
  .topHeadAboutUs {
    padding: 0px 0px 0px 0px;
  }
}
