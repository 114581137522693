.bgColor {
  background: linear-gradient(297.65deg, #2fc2fe 14.83%, #0094f6 83.99%);
  padding: 25px;
  position: relative;
}
.bgColor svg {
  box-shadow: 0px 0px 4px 0px #00000033;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
  background: #fff;
  position: absolute;
  top: 16px;
}
.bgColor h6 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.textHeading {
  font-size: 30px;
  font-weight: 700;
  line-height: 48.41px;
  text-align: center;
  color: #333333;
  margin: 10px 0px;
  text-transform: uppercase;
}

.textHeading span {
  color: #0094f6;
  text-transform: uppercase;
}

.sheduleBox {
  box-shadow: 0px 0px 4px 0px #00000026;
  background: #ffffff;
  padding: 10px;
}
.sheduleInnerBox {
  box-shadow: 0px 0px 2px 0px #0000000d;
  border: 0.6px solid #cecece;
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
}
.sheduleToggleFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
.sheduleToggleFlex h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: #000000;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.inputTime {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.inputTime .cancelIcon svg {
  color: #ec3b1e;
  width: 24px;
  height: 33px;
  cursor: pointer;
  margin-left: 3px;
}
.inputTime .textSeprate {
  font-size: 13px;
  font-weight: 600;
  color: #333333;
  padding: 0px 10px;
}

.updateBtnSpace {
  margin-top: 20px;
  text-align: right;
  margin-bottom: 30px;
}

.updateBtnSpace button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  max-width: 415px;
  border: 1px solid #0f7dff70;
  background: linear-gradient(297.65deg, #2fc2fe 14.83%, #0094f6 83.99%);
  height: 50px !important;
  width: 100%;
  border-radius: var(--bs-border-radius);
}
.addText {
  color: #0f7dff;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: right;
  margin-bottom: 0px;
  margin-right: 20px;
  cursor: pointer;
}
.chatFeature {
  text-align: end;
  padding-bottom: 10px;
  cursor: pointer;
}
.chatFeature img {
  margin-right: 30px;
  width: 40px;
  height: 40px;
}

/* ----tutorial---- */
.tutorialBottomText {
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
  color: #626262;
  margin-top: 10px;
}

.tutorialBox {
  box-shadow: 0px 0px 4px 0px #00000026;
  background: #ffffff;
  padding: 10px;
  margin-bottom: 22px;
}

@media only screen and (max-width: 991px) {
  .textHeading {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .textHeading {
    font-size: 30px;
  }
  .tutorialBottomText {
    font-size: 14px;
  }
}

@media only screen and (max-width: 425px) {
  .textHeading {
    font-size: 26px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 375px) {
  .textHeading {
    font-size: 23px;
  }
}
