.floorSubmitBtn {
  width: 100%;
  padding: 10px;
}

.supportDriverBtn button {
  border: 1px solid #2fc2fe;
}

.activeFloor {
  color: white !important;
  background: #2fc2fe !important;
  width: 100%;
  text-align: center;
  height: 40px;
  border-radius: 4px;
}
