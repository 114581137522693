.headContainer {
  display: flex;
  justify-content: center;
  /* background-color: #f5f3f3; */
  font-size: 25px;
  font-weight: 700;
  padding: 8px;
}
.tabsContainer {
  max-width: 50%;
  margin: 0 auto;
}
.jobSearchBox {
  position: relative;
  max-width: 48%;
  margin: 0 auto;
}
.jobSearchInput {
  box-shadow: 0px 2px 2px 0px #00000026;
  border: 1px solid #e7e7e7;
  border-radius: 110px;
  height: 50px;
  padding-left: 50px;
  padding-top: 9px;
}
.jobSearchBox svg {
  position: absolute;
  top: 11px;
  left: 10px;
  width: 26.35px;
  height: 26.35px;
  color: #555555;
}
.jobSearchBox img {
  position: absolute;
  top: 5px;
  right: 19px;
  cursor: pointer;
}

.topWrapper {
  /* margin: 22px 60px 0px 60px; */
}

.topMainContainer {
  margin-top: 22px;
  background-color: #fafafa;
  padding: 12px;
  border-radius: 4px;
  border: 0.5px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}

.locationContainer {
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  border: 0.3px solid #ededed;
}
.sourceContainer {
  display: flex;
  gap: 6px;
  align-items: center;
}
.sourceName {
  font-size: 12px;
  font-weight: 600;
}
.sourceValue {
  font-size: 10px;
  font-weight: 400;
  color: #555555;
  margin-left: 30px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowed text */
  text-overflow: ellipsis;
}
.hrSection {
  margin: 8px 0px 8px 0px;
}
.infoContainer {
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  border: 0.3px solid #ededed;
}
.infoAvailabilty {
  display: flex;
  justify-content: space-between;
}
.availabilityTitle {
  font-size: 12px;
  font-weight: 500;
}
.availabilityValue {
  font-size: 12px;
  font-weight: 400;
  color: #555555;
}
.stopsValue {
  font-size: 12px;
  font-weight: 500;
  color: #0f7dff;
}
.infoCategory {
  display: flex;
}
.categoryName {
  font-size: 12px;
  font-weight: 500;
  color: #0f7dff;
}
.infoHelper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicleType {
  font-size: 12px;
  font-weight: 600;
  color: white;
  background: #0f7dff;
  padding: 4px;
  border-radius: 4px;
  margin-left: 4px;
  float: right;
}

.chatFeature {
  text-align: end;
  margin-right: 24px;
  margin-bottom: 12px;
  cursor: pointer;
}

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .categoryName {
    font-size: 10px;
  }
  .vehicleType {
    font-size: 10px;
  }
  .availabilityTitle {
    font-size: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 810px) {
  .categoryName {
    font-size: 10px;
  }
  .vehicleType {
    font-size: 10px;
  }
  .availabilityTitle {
    font-size: 10px;
  }
}

@media screen and (max-width: 570px) {
  .tabsContainer {
    max-width: 80%;
  }
  .jobSearchBox {
    max-width: 75%;
  }
}

@media screen and (max-width: 340px) {
  .categoryName {
    font-size: 10px;
  }
  .vehicleType {
    font-size: 10px;
  }
  .availabilityTitle {
    font-size: 10px;
  }
  .availabilityValue {
    font-size: 10px;
  }
}
.viaShow {
  float: right;
}
.addressRouteWrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.subTitleName {
  font-weight: 600;
  font-size: 16px;
}
.titleDescription {
  font-weight: 400;
  font-size: 14px;
  color: #555555;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.subLocationContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.subLocationInner {
  display: flex;
  gap: 12px;
}
.leftRouteLine {
  border-left: 1px dashed #a5a5a5;
  width: 2px;
  height: 100%;
}
.routeLinePath {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}
.hrLineDraw {
  background-color: #d9d9d9;
  height: 1px;
}
.hrLineStops {
  background-color: #d9d9d9;
  height: 1px;
  width: 50%;
}
