.topHeadTracking {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f7dff;
  color: white;
  padding: 0px 90px 0px 90px;
}
.toptrackTitle {
  font-weight: 600;
  font-size: 20px;
}
.topHeadBackBtn {
  padding: 12px 0px;
}
.topHeadBackBtn svg {
  background-color: white;
  color: black;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
}
.trackDescriptionText {
  margin: 22px 90px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #606060;
}
.trackContainer {
  margin: 32px 0px;
}
.trackContainer2 {
  display: flex;
  justify-content: center;
}

.topMapContainer {
  margin: 22px 90px 60px 90px;
}

.trackingDateTime {
  color: #626262;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
}

.chatIconFeature {
  display: flex;
  justify-content: flex-end;
  margin: 0px 22px 22px 0px;
}

@media only screen and (max-width: 400px) {
  .trackLabel {
    font-size: 12px;
  }
}
@media screen and (max-width: 550px) {
  .topHeadTracking {
    padding: 0px 60px 0px 60px;
  }
}

@media screen and (max-width: 480px) {
  .topHeadTracking {
    padding: 0px 22px 0px 22px;
  }
}

@media screen and (max-width: 750px) {
  .topMapContainer {
    margin: 22px 40px 60px 40px;
  }
  .trackDescriptionText {
    margin: 22px 40px;
  }
}

@media only screen and (max-width: 400px) {
  .trackingDateTime {
    font-size: 10px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 700px) {
  .trackContainer {
    overflow: auto;
  }
}
