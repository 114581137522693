.topHeadSummmary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f7dff;
  color: white;
  padding: 0px 90px 0px 90px;
}
.topHeadBackBtn {
  padding: 12px 0px;
}
.topHeadBackBtn svg {
  background-color: white;
  color: black;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
}
.topTitle {
  font-weight: 600;
  font-size: 20px;
}
.mainContainerWrapper {
  background-color: #f8f8f8;
}
.mainContainer {
  padding: 22px 90px 22px 90px;
}
.subContainer {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 6px;
}
.subLocationContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.titleName {
  font-weight: 600;
  font-size: 18px;
  word-break: break-all;
}
.titleNameDimension {
  font-weight: 600;
  font-size: 18px;
  /* margin-bottom: 8px; */
}
.tabCount {
  color: #828282;
}
.ownQuantity {
  /* margin-top: 8px; */
}
.subTitleName {
  font-weight: 500;
  font-size: 16px;
}
.stopTitle {
  font-weight: 500;
  font-size: 14px;
}
.titleDescription {
  font-weight: 300;
  font-size: 14px;
}
.floorSection {
  display: flex;
  gap: 20px;
}
.floorSectionTitle {
  font-size: 12px;
  font-weight: 500;
}
.floorValue {
  color: #0f7dff;
  font-size: 12px;
}

.liftCheckYes {
  color: #0f7dff;
  font-size: 12px;
}
.liftCheckNo {
  color: red;
  font-size: 12px;
}
.itemsSection {
  display: flex;
  justify-content: space-between;
}
/* .ownItemsSection {
  display: flex;
} */

.itemsSubSection {
  display: flex;
}
.furnitureName {
  font-size: 20px;
  font-weight: 600;
  color: #0f7dff;
}
.dimensionType {
  font-size: 14px;
  font-weight: 600;
  color: #626262;
}
.dimensionValue {
  font-size: 14px;
  font-weight: 600;
}
.titleHeading {
  font-weight: 600;
  font-size: 18px;
  margin: 18px 0px 4px 0px;
}

.ownTitleHeading {
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0px 0px 0px;
}

.furnitureContainer {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
}

.furnitureContainerSlider {
  background-color: white;
  padding: 16px 40px;
  border-radius: 6px;
  display: flex !important;
  flex-direction: column;
  gap: 6px;
}
.singleFurnitureContainerSlider {
  background-color: white;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.vehicleType {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicleImage {
  height: 60px;
  width: 90px;
  object-fit: contain;
}
/* .isHelper {
  color: #ec3b1e;
  font-size: 15px;
  font-weight: 500;
} */
.isHelper {
  display: flex;
  font-size: 15px;
  font-weight: 500;
}
.verticalDivider {
  border-left: 1px solid grey;
  margin: 5px;
}
.availabilityContainer {
  margin-top: 12px;
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}
.availabilityDay {
  color: #0f7dff;
  font-size: 15px;
  font-weight: 500;
}
.estimatedTime {
  font-size: 15px;
  font-weight: 400;
  margin-top: 18px;
}
.estimatedTime span {
  color: #3159d2;
  font-weight: 500;
}
.bottomBtnEdit {
  font-weight: 600;
  width: 100%;
  padding: 12px;
}
.bottomBtnPrice {
  font-weight: 600;
  width: 100%;
  background-color: transparent;
  color: #0f7dff;
  padding: 12px;
}
.chatFeature {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: -44px -68px 0px 0px;
}

@media screen and (max-width: 550px) {
  .mainContainer {
    padding: 22px 60px 22px 60px;
  }
  .chatFeature {
    margin: -44px -52px 0px 0px;
  }
  .topHeadSummmary {
    padding: 0px 60px 0px 60px;
  }
}

@media screen and (max-width: 480px) {
  .mainContainer {
    padding: 22px;
  }
  .chatFeature {
    margin: 0px;
  }
  .topHeadSummmary {
    padding: 0px 22px 0px 22px;
  }
}
.PrevSlickArrow {
  position: absolute;
  bottom: 42px;
  left: 3px;
  z-index: 1;
}

.NextSlickArrow {
  position: absolute;
  bottom: 42px;
  right: 3px;
  z-index: 1;
}

.PrevSlickArrowCustom {
  position: absolute;
  bottom: 84px;
  left: 3px;
  z-index: 1;
}

.NextSlickArrowCustom {
  position: absolute;
  bottom: 84px;
  right: 3px;
  z-index: 1;
}

.couponSection {
  margin-bottom: 28px;
}

.selectedCouponView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  height: 50px;
  padding: 16px;
}

.selectedCode {
  font-size: 13px;
  font-weight: 700;
  color: #4770eb;
}

.selectedPercent {
  font-size: 13px;
  font-weight: 700;
  color: #202020;
}

.selectedCoupon {
  display: flex;
  align-items: center;
  gap: 12px;
}

.selectedCoupon svg {
  cursor: pointer;
}

.rightQuantity {
  margin-right: 14px;
}
.addressRouteWrapper {
  display: flex;
  gap: 12px;
}
.leftRouteLine {
  border-left: 1px dashed #a5a5a5;
  width: 2px;
  height: 100%;
}
.routeLinePath {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  padding: 32px 0px;
}
.hrLineDraw {
  background-color: #d9d9d9;
  height: 1px;
}
.hrLineStops {
  background-color: #d9d9d9;
  height: 1px;
  width: 50%;
}
@media screen and (max-width: 991px) {
  .singleFurnitureContainerSlider {
    margin-top: 12px;
  }
}

.totalMileage {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.totalMileage span {
  color: #0f7dff;
}

.topDimensionWrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

@media screen and (max-width: 570px) {
  .dimensionType {
    font-size: 12px;
  }
  .dimensionValue {
    font-size: 12px;
  }
}
.verticalLineOwn {
  width: 100%;
  height: 0.5px;
  background-color: #b6b6b6;
  margin: 6px 0px 6px 0px;
}
