.topHead {
  display: flex;
  justify-content: space-between;
  margin: 0px 80px;
}

.topSubHead {
  background-color: #fbfbfb;
  height: max-content;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  /* padding-top: 22px; */
}

.topSubHeadTitle {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.fieldWrapper {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}
.customError {
  text-align: left;
  color: #dc3545;
  font-size: 14px;
}
.btnSubmit {
  margin-top: 4px;
}

.btnSubmit button {
  width: 100%;
  height: 50px;
}

@media screen and (max-width: 470px) {
  .topSubHeadTitle {
    font-size: 25px;
  }
}

.chatcontactInfo {
  background-color: #fbfbfb;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fieldWrapper {
    margin: 18px 32px 0px 32px;
  }
}

.contactImage {
  max-width: 342px;
  margin: 22px auto;
  display: flex;
}

.contactImage img {
  text-align: center;
  width: 100%;
  max-width: 342px;
  object-fit: contain;
}

@media screen and (max-width: 470px) {
  .contactImage {
    margin: 16px 80px;
  }
  .topHead {
    margin: 0px 20px;
  }
}
