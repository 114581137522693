.forgotPage {
  margin: 0px auto;
  max-width: 485px;
  /* padding: 0px 20px; */
  /* margin-bottom: 30px; */
}

.logoImage {
  text-align: center;
  width: 100%;
}

.logoImage img {
  /* width: 100%;
  max-width: 237px;
  margin-top: 18px;
  margin-bottom: 30px; */
  width: 100%;
  max-width: 200px;
}

.forgotPara {
  font-size: 20px;
  font-weight: 500;
  color: #6b6b6b;
  line-height: 30px;
  text-align: center;
}

/* .forgotButtonSpace {
  margin-top: 70px;
} */

.formBlocks {
  /* border-radius: 50px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  width: 100%;
  max-width: 820px;
  margin: 0px auto;
  padding: 5px 20px; */

  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

@media only screen and (max-width: 575px) {
  .forgotPara {
    font-size: 16px;
    line-height: 23px;
  }
}
