.resetImage {
  width: 100%;
  max-width: 100px;
  margin: 8px auto;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  border-radius: 50%;
  /* height: 100px;  */
}

.resetImage img {
  width: 100%;
  width: 60px;
  margin: 35px 19px;
}
.resetText h3 {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 49px;
  text-align: center;
}

.resetText p {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

/* .resetButtonSpace {
  margin-top: 50px;
} */

@media only screen and (max-width: 575px) {
  .resetText h3 {
    font-size: 28px;
    line-height: 38px;
  }
}

.rightSectionImg {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .rightSectionImg {
    height: 100vh;
  }
}

.formBlocks {
  /* border-radius: 50px;
    background: #ffffff;
    box-shadow: 0px 3px 12px 0px #ffffff33;
    width: 100%;
    max-width: 820px;
    margin: 0px auto;
    margin: 0px auto;
    padding: 5px 20px; */

  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}
