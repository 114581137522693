.footerSection {
  background: #202020;
}

.footerManage {
  border-bottom: 0.5px solid #ffffff66;
}

.copyText {
  color: #ffffff;
  text-align: center;
  padding: 10px 60px;
  font-weight: 400;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}

.displayContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}

.displayContent img {
  width: 100%;
}

.displayLink a {
  font-weight: 700;
  font-size: 14px;
  margin-left: 20px;
  text-decoration: unset !important;
  color: #ffffff !important;
  font-family: Helvetica;
}

@media only screen and (min-width: 391px) and (max-width: 450px) {
  .displayLink a {
    font-size: 12px;
    margin-left: 10px;
  }

  .displayContent img {
    max-width: 150px;
  }
}

@media only screen and (min-width: 341px) and (max-width: 390px) {
  .displayLink a {
    font-size: 12px;
    margin-left: 10px;
  }

  .displayContent img {
    max-width: 100px;
  }
}

@media only screen and (max-width: 340px) {
  .displayLink a {
    font-size: 10px;
    margin-left: 10px;
  }

  .displayContent img {
    max-width: 100px;
  }
}

.footerInfo {
  color: white;
  display: flex;
  padding: 22px;
  justify-content: space-evenly;
  gap: 12px;
}

@media only screen and (max-width: 991px) {
  .footerInfo {
    padding: 22px 0px;
  }
}

.footerHeading {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: -10px;
}

.verticalDivider {
  height: 17px;
  width: 2px;
  border-radius: 4px;
  background-color: #0f7dff;
}
.infoSection {
  display: flex;
  gap: 12px;
}
.footerItems {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 500;
  font-size: 15px;
}

.footerSocialInfo {
  display: flex;
  margin-top: -12px;
  margin-left: -8px;
}
.footerSocialInfo img {
  width: 42px;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .footerHeading {
    font-size: 18px;
  }
  .footerSocialInfo img {
    width: 34px;
  }
}

@media only screen and (max-width: 771px) {
  .footerInfo {
    justify-content: space-evenly;
    gap: 22px;
    flex-direction: column;
    align-items: center;
  }
  .footerItems {
    align-items: center;
  }
  .footerHeading {
    font-size: 20px;
  }
}

.policyText {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.footerPolicy {
  display: flex;
  gap: 18px;
}
@media only screen and (max-width: 671px) {
  .copyText {
    font-size: 10px;
  }
  .policyText {
    font-size: 10px;
  }
}
@media only screen and (max-width: 507px) {
  .copyText {
    flex-direction: column;
    gap: 8px;
  }
  .footerPolicy {
    justify-content: center;
  }
}

.footerSocialInfoStore {
  display: flex;
  gap: 8px;
  margin-top: -6px;
}

.footerSocialInfoStore img {
  width: 100px;
}

.footerSocialSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
