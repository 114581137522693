.faqContainer {
  margin-top: 102px;
  margin-bottom: 22px;
}
.faqLists {
  padding: 6px 0px;
}
.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 22px;
}
.notFoundMessage {
  color: grey;
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .faqContainer {
    margin-top: 32px;
  }
}
