.formBlock {
  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 40px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

.resetImage {
  width: 100%;
  max-width: 100px;
  margin: 8px auto;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  border-radius: 50%;
  /* height: 100px; */
}

.resetImage img {
  width: 100%;
  width: 60px;
  margin: 35px 19px;
}

.resetText h3 {
  color: #000000;
  font-size: 25px;
  font-weight: 700;
  line-height: 49px;
  text-align: center;
}

.loginBtn button {
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  height: 56px;
  width: 100%;
  border-radius: 1px;
  /* margin-top: 35px; */
  border-radius: var(--bs-border-radius);
}

@media only screen and (max-width: 575px) {
  .resetText h3 {
    font-size: 22px;
    line-height: 38px;
  }

  .formBlock {
    padding: 30px 20px;
  }
}
