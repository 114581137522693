.homeJobs {
  width: 100%;
  max-width: 630px;
  margin: 15px auto 0px;
}
.homeJobs p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #202020;
  margin-bottom: 6px;
}
.homeJobs h3 {
  font-family: Inter;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: #6aa6be;
}

.searchBox {
  position: relative;
  margin-top: 30px;
}

.searchInput {
  box-shadow: 0px 2px 2px 0px #00000026;
  border: 1px solid #e7e7e7;
  border-radius: 110px;
  height: 50px;
  padding-left: 50px;
  padding-top: 9px;
}

.searchInput:focus {
  border-color: #e7e7e7 !important;
}
.searchInput::placeholder {
  color: #828282 !important;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0em;
}

.searchBox svg {
  position: absolute;
  top: 11px;
  width: 26.35px;
  height: 26.35px;
  color: #555555;
  margin-left: 11px;
}

.searchBox img {
  position: absolute;
  top: 5px;
  right: 19px;
  cursor: pointer;
}
.filterIcon {
  position: absolute;
  top: 5px;
  right: 16px;
  cursor: pointer;
  color: #0094f6 !important;
}
.homeJobBox {
  box-shadow: 0px 1px 4px 0px #00000026;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.topMainContainer {
  margin-top: 22px;
  background-color: #fcfcfc;
  padding: 12px;
  border-radius: 4px;
  border: 0.3px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.locationContainer {
  /* display: flex;
  justify-content: space-between;
  /* padding: 12px; */
  /* min-height: 60px;  */
  /* background-color: white; */
  /* padding: 12px; */
  /* border-radius: 4px; */
  /* border: 0.3px solid #ededed; */
}

.verticalDivider {
  min-width: 2%;
  margin: auto;
}
.verticalDivider span {
  border: 0.5px solid #bbbbbb;
  padding: 8px 0px;
}
.topDiv {
  min-width: 49%;
}
.sourceContainer {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  /* min-width: 49%; */
}
.sourceName {
  font-size: 16px;
  font-weight: 500;
}
.sourceValue {
  font-size: 12px;
  font-weight: 400;
  color: #555555;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}
.hrSection {
  margin: 0px;
}

.topDivSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}
.jobTime {
  font-size: 12px;
  font-weight: 500;
}
.jobDate {
  color: #0094f6;
  font-size: 12px;
  font-weight: 400;
}
.stopTitle {
  font-size: 15px;
  font-weight: 500;
}
.stopValue {
  color: #0094f6;
  font-size: 15px;
  font-weight: 500;
}
.stopSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.acceptSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.acceptBtns {
  display: flex;
  gap: 12px;
  width: 70%;
  justify-content: space-between;
}
.jobAmount {
  color: #0094f6;
  font-size: 20px;
  font-weight: 700;
}
.rejectBtn {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #ec3b1e;
  border-radius: 4px;
  color: #ec3b1e;
  padding: 6px 22px 6px 6px;
  cursor: pointer;
  width: 100%;
}
.rejectBtn svg {
  height: 20px;
  width: 20px;
}
.acceptBtn {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #058321;
  border-radius: 4px;
  color: #058321;
  padding: 6px 22px 6px 6px;
  cursor: pointer;
  width: 100%;
}
.acceptBtn svg {
  height: 20px;
  width: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jobAmount {
    font-size: 15px;
  }
  .rejectBtn {
    padding: 6px 12px 6px 6px;
  }
  .acceptBtn {
    padding: 6px 12px 6px 6px;
  }
}

@media screen and (max-width: 450px) {
  .sourceContainer {
    align-items: flex-start;
  }
  .sourceName {
    font-size: 12px;
  }
  .sourceValue {
    font-size: 10px;
  }
  .jobAmount {
    font-size: 15px;
  }
  .rejectBtn {
    padding: 6px;
  }
  .acceptBtn {
    padding: 6px;
  }
}

.furnitureContainerSlider {
  padding: 0px 40px;
  border-radius: 6px;
  display: flex !important;
  flex-direction: column;
  gap: 6px;
}

.itemsSection {
  display: flex;
  justify-content: space-between;
}
.PrevSlickArrow {
  position: absolute;
  bottom: 20px;
  left: 3px;
  z-index: 1;
}
.NextSlickArrow {
  position: absolute;
  bottom: 20px;
  right: 3px;
  z-index: 1;
}
.titleName {
  font-weight: 500;
  font-size: 12px;
  color: #626262;
}
.furnitureName {
  font-size: 16px;
  font-weight: 600;
  color: #0f7dff;
}
.tabsCounter {
  font-size: 12px;
  font-weight: 500;
}
.tabCount {
  color: #828282;
}
.rightQuantity {
  font-size: 12px;
  font-weight: 500;
  margin-right: 6px;
}

.singleFurnitureContainerSlider {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.addressRouteWrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.viaShow {
  float: right;
}
.subLocationInner {
  display: flex;
  gap: 12px;
}
.routeLinePath {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}
.leftRouteLine {
  border-left: 1px dashed #a5a5a5;
  width: 2px;
  height: 100%;
}
.subTitleName {
  font-weight: 500;
  font-size: 15px;
  color: #828282;
}
.titleDescription {
  font-weight: 600;
  font-size: 18px;
  /* color: #555555; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.subLocationContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filterDotIcon {
  position: absolute;
  right: 25px;
  top: 17px;
  background: #ec3b1e;
  border-radius: 50%;
  padding: 3px;
  color: white;
}
