.main {
  min-height: calc(100vh - 242px);
  overflow-x: hidden;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: unset !important;
}

.errorMsg {
  color: #ff0000;
  font-weight: 400;
  font-size: 14px;
}

.setIconPassword {
  position: relative;
}

.passwordicon {
  right: 13px;
  top: 40px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
  color: #afaeae;
  background-color: white;
}

.passwordicon svg {
  margin-left: 6px;
}

.formTopSpace {
  padding-top: 40px;
}

.formSection label {
  color: #202020;
  font-weight: 500;
  font-size: 15px;
  line-height: 18.15px;
}

.formSection input {
  color: #000000 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  height: 50px !important;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  border: 1px solid #bfbfbf;
  background: #ffffff;
}

.formSection input::placeholder {
  color: #7a7a7a !important;
}

.formSection textarea.form-control {
  color: #000000 !important;
  font-weight: 400;
  font-size: 14px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  border: 1px solid #bfbfbf;
  background: #ffffff;
}

.formSection textarea.form-control::placeholder {
  color: #7a7a7a !important;
}

.formSection input.form-control:focus {
  border: 1px solid #bfbfbf !important;
}

.commonButton button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(297.65deg, #2fc2fe 14.83%, #0094f6 83.99%);
  height: 50px !important;
  width: 100%;
  border-radius: var(--bs-border-radius);
}

.cancelButton button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  height: 50px !important;
  width: 100%;
  border-radius: 3px;
  /* margin-top: 30px; */
  margin-top: 15px;
  background: #ffffff;
  border: 3px solid #0094f6;
}

.commonheading {
  /* font-weight: 700;
  font-size: 50px;
  color: #202020; */
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 49px;
}

/* .leftContent {
  margin: 0px auto;
  max-width: 485px;
  padding: 40px 20px;
} */

/* social login css */
.socialbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  margin-top: 10px;
}

.SocialLoginBtn img {
  width: 40px;
}

.SocialLoginBtn {
  margin-right: 20px;
}

.SocialLoginBtn button.kep-login-facebook.metro {
  background: unset !important;
  border: unset !important;
  padding: 0px !important;
}

/* otp input css */

.otpbox input::placeholder {
  font-size: 25px;
  color: #878787;
}

.otpInput {
  display: flex;
  justify-content: center;
}

.inputStyle {
  width: 56px !important;
  height: 40px !important;
  margin: 0 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.inputStyle:focus-visible {
  box-shadow: none !important;
  outline: none;
}

@media only screen and (max-width: 400px) {
  .inputStyle {
    width: 45px !important;
    height: 40px !important;
    margin: 0 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.otpError {
  text-align: center;
  margin-top: 7px;
  /* margin-bottom: 50px; */
}

/* @media only screen and (max-width: 575px) {
  .commonheading {
    font-size: 35px;
    line-height: 44.51px;
  }
} */

/* @media only screen and (min-width: 576px) and (max-width: 767px) {
  .commonheading {
    font-size: 45px;
    line-height: 42.51px;
  }
} */
/* 
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .commonheading {
    font-size: 44px;
    line-height: 49.51px;
  }
} */

@media only screen and (max-width: 575px) {
  .commonheading {
    font-size: 28px;
    line-height: 38px;
  }
}

/* phone input css */

.phoneInputSet .form-control {
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
  height: 50px !important;
  box-shadow: 0px 3px 12px 0px #ffffff33 !important;
  border: 1px solid #bfbfbf !important;
  background: #ffffff !important;
  width: 100% !important;
}

.phoneInputSet .form-control::placeholder {
  color: #7a7a7a !important;
}

.phoneInputSet .react-tel-input .flag-dropdown {
  border-right: none !important;
  background: #ffffff !important;
}

/* color modal css */
.colorModal .modal-header {
  display: block;
}

.colorInput label {
  display: block;
  margin-bottom: 16px;
  color: black;
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  background: #dcdcdc;
  width: 100%;
  text-align: center;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}

.colorInput input[type="radio"] {
  appearance: none;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
  display: none;
}

.colorInput input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #fff;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.colorInput input[type="radio"]:checked::before {
  transform: scale(1);
}

.colorInput input[type="radio"]:checked {
  background-color: #0094f6;
  border: 2px solid #0094f6;
}

/* document alert modal css */
.alertbox {
  background: linear-gradient(180deg, #0094f6 0%, #2fc2fe 100%);
  height: 100px;
}

.alertIcon {
  text-align: center;
  border: 2px solid #0094f6;
  border-image-source: linear-gradient(180deg, #0094f6 0%, #2fc2fe 100%),
    linear-gradient(0deg, #ec3b1e, #ec3b1e);
  border-radius: 50%;
  width: 100%;
  max-width: 120px;
  height: 120px;
  margin: 0px auto;
  margin-top: -78px;
  background: #ffffff;
}

.alertIcon svg {
  color: #ec3b1e;
  margin: 28px auto;
  font-size: 55px;
}

.alertIconMsg {
  text-align: center;
  margin: 20px auto;
  width: 100%;
  max-width: 255px;
}

.alertIconMsg h4 {
  color: red;
  font-size: 35px;
  font-weight: 600;
  line-height: 42.36px;
}

.alertIconMsg p {
  color: #555555;
  font-size: 21px;
  font-weight: 400;
  line-height: 25.41px;
}

.alertButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  gap: 12px;
}

.alertButton button.alertLeftButton {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(180deg, #0094f6 0%, #2fc2fe 100%);
  height: 40px;
  width: 100%;
  border-radius: 45px;
  margin-left: 20px;
}

.alertButton button.alertRightButton {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  height: 40px;
  width: 100%;
  border-radius: 45px;
  background: #ffffff;
  border: 3px solid #0094f6;
}

@media only screen and (max-width: 450px) {
  .alertButton {
    padding: 20px 0px;
  }

  .alertButton button.alertRightButton {
    margin-left: 10px;
  }

  .alertIconMsg p {
    font-size: 17px;
  }
}

/* custom vehicle select radio button css */
.vehicleInput input[type="radio"] {
  display: none;
}

.vehicleInput label {
  height: 100%;
  display: block;
  background: white;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 0px 2px 0px #00000026;
  position: relative;
  border: 0.3px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
}

.vehicleInput input[type="radio"]:checked + label {
  background: #2fc2fe;
}

.vehicleInput input[type="radio"]:checked + label div h2 {
  color: #ffffff !important;
}

.vehicleInput input[type="radio"]:checked + label div p {
  color: #333333 !important;
}

/* .fade.colorModal.modal.show {
  z-index: 10000000 !important;
} */

/* driver header css */
.activeShow {
  align-items: center;
}

.activeShow a {
  font-size: 20px;
  /* color: #828282; */
  color: #bbbbbb;
  font-weight: 500;
  text-decoration: none !important;
  margin-left: 30px;
}

.activeShow a.active {
  font-size: 20px;
  font-weight: 500;
  /* color: #0094f6; */
  color: #ffffff;
  text-decoration: none !important;
  margin-left: 30px;
}

@media only screen and (min-width: 992px) {
  .spaceTopManageDriver {
    margin-top: 74px;
  }
}

/* show setting active class */
a.driverActive {
  text-decoration: none !important;
}

a.driverActive.active p {
  color: #0094f6 !important;
}

a.driverActive.active svg {
  color: #0094f6 !important;
}

/* tab section css in driver edit profile */
ul.tabSection.nav.nav-tabs {
  border: 2px solid #f6f6f6 !important;
  border-radius: 48px !important;
  margin: 20px 0px 25px;
}

.tabSection.nav-tabs .nav-link {
  border: unset !important;
}

.tabSection.nav-tabs .nav-link {
  background-color: transparent !important;
  color: #555555 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
}

.tabSection li.nav-item {
  width: 50%;
}

.tabSection.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.tabSection.nav-tabs .nav-link.active {
  background: #2fc2fe !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0em;
  color: #ffffff !important;
  border-radius: 48px;
  width: 100%;
  margin: 2px;
}

@media only screen and (max-width: 400px) {
  .tabSection.nav-tabs .nav-link.active {
    font-size: 14px !important;
  }

  .tabSection.nav-tabs .nav-link {
    font-size: 14px !important;
  }
}

.formTopSpaceCls {
  padding-top: 20px;
}

/* driver radius slider css */
.SliderSet .rc-slider-track,
.SliderSet .rc-slider-tracks {
  height: 10px !important;
  background-color: #0094f6;
}
.SliderSet .rc-slider-rail {
  height: 10px !important;
}

.SliderSet
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #0094f6 !important;
  box-shadow: 0 0 0 3px #0094f6 !important;
}

.SliderSet .rc-slider-handle {
  width: 20px !important;
  height: 20px !important;
  margin-top: -6px !important;
  background-color: #fff;
  border: 2px solid #0094f6 !important;
  box-shadow: 0 0 0 3px #0094f6 !important;
}

/* custom services select checkbox css */
.serviceInput input[type="checkbox"] {
  display: none;
}

.serviceInput label {
  height: 100%;
  display: block;
  background: white;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 0px 2px 0px #00000026;
  position: relative;
  border: 1px solid #e3e3e3;
  height: 80px;
  border-radius: 70px;
  cursor: pointer;
}

.serviceInput input[type="checkbox"]:checked + label {
  border: 2px solid #0094f6;
  box-shadow: 0px 2px 2px 0px #00000026;
}

.serviceInput input[type="checkbox"]:checked + label div h2 {
  color: #000000 !important;
}

/* .serviceInput input[type="checkbox"]:checked + label div p {
  color: #058321 !important;
} */

/* vehicle type modal css */
.vehicleInputDisplay {
  display: flex !important;
  align-items: center;
}

.vehicleInputDisplay h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0px;
}

.vehicleInputDisplay img {
  width: 50px;
  /* height: 37.5px; */
  margin-right: 20px;
}

@media only screen and (max-width: 410px) {
  .vehicleInputDisplay h2 {
    font-size: 11px;
    line-height: 18px;
  }
  .vehicleInputDisplay img {
    width: 50px;
    margin-right: 6px;
  }
  .serviceInput label {
    padding: 4px;
  }
  .serviceInput input[type="checkbox"]:checked + label::after {
    top: 37% !important;
    right: 14px !important;
    width: 7px !important;
    height: 14px !important;
  }
}
@media only screen and (min-width: 411px) and (max-width: 440px) {
  .vehicleInputDisplay h2 {
    font-size: 15px;
    line-height: 18px;
  }
  .vehicleInputDisplay img {
    width: 40px;
    margin-right: 6px;
  }
}

/* Right tick design for checked checkbox */
.serviceInput input[type="checkbox"]:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 32%;
  right: 39px;
  width: 10px;
  height: 19px;
  border: 3px solid #0094f6;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}

/* all type input custom design */
.customChekbox label {
  height: 100%;
  display: block;
  background: white;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 0px 2px 0px #00000026;
  position: relative;
  border: 1px solid #e3e3e3;
  height: 80px;
  border-radius: 70px;
  position: relative;
  cursor: pointer;
}

.customChekbox {
  display: block;
  margin-bottom: 15px;
}

.customChekbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.customChekbox label:before {
  content: "";
  background-color: transparent;
  border: 4px solid #0094f6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 30px;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  margin-left: 12px;
}

.customChekbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 29px;
  left: 38px;
  width: 8px;
  height: 16px;
  border: 3px solid #0094f6;
  border-width: 0 4px 5px 0;
  transform: rotate(45deg);
}

.dateInput input {
  width: 100%;
  max-width: 315px !important;
  margin: 0px auto;
}

/* driver datepicker css */
.muiDatepicker {
  width: 100%;
}

.muiDatepicker input {
  padding: 0px 14px !important;
  border-radius: 5px !important;
  border: 1px solid #bfbfbf !important;
}

.muiDatepicker input:focus-visible {
  outline: unset !important;
}
.muiDatepicker
  .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
/* 
.muiDatepicker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 14px !important;
  border-radius: 5px;
  border: 1px solid #bfbfbf !important;
} */

/* .muiDatepicker
  .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root:focus-visible {
  outline: unset !important;
} */

.muiDatepicker .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #6d6d6d !important;
  padding: 0px 5px !important;
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
}

.muiDatepicker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #6d6d6d;
  border: none !important;
}

.muiDatepicker
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #bfbfbf !important;
}

.muiDatepicker .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #6d6d6d !important;
}

.muiDatepicker
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #bfbfbf !important;
}

.muiDatepickerfull {
  width: 100% !important;
  max-width: 315px !important;
  margin: 0px auto !important;
  display: flex !important;
}
/* .muiDatepickerfull
  .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
  max-width: 315px !important;
  margin: 0px auto !important;
  display: flex;
} */

.toggle_switch .form-check-input:focus {
  box-shadow: unset !important;
}

.toggle_switch .form-check-input {
  height: 26px;
  width: 47px;
}

.muiYearSelection {
  background-color: unset;
  width: 100%;
}
.muiYearSelection .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border: none !important;
}

.muiYearSelection input {
  padding: 0px 14px !important;
  border: unset !important;
}

.muiYearSelection .MuiInputBase-input {
  width: 12%;
}
.muiYearSelection .MuiInputBase-root {
  padding-right: unset;
}
.muiYearSelection .MuiSvgIcon-root {
  display: none;
}
.muiYearSelection .MuiButtonBase-root {
  width: 100%;
  height: 50px;
  border-radius: unset;
}
.muiYearSelection .MuiButtonBase-root:hover {
  background-color: unset;
}
.muiYearSelection .MuiInputAdornment-root {
  width: 100%;
  margin-left: -18%;
}

@media only screen and (max-width: 400px) {
  .muiYearSelection .MuiInputBase-input {
    width: 16%;
  }
  .muiYearSelection .MuiInputAdornment-root {
    width: 100%;
    margin-left: -25%;
  }
}

.muiFullYearSelection {
  background-color: unset;
}
.muiFullYearSelection input {
  padding: 0px 14px !important;
  border: unset !important;
}
.muiFullYearSelection .MuiInputBase-input {
  width: 38%;
}
.muiFullYearSelection .MuiInputBase-root {
  padding-right: unset;
}
.muiFullYearSelection .MuiSvgIcon-root {
  display: none;
}
.muiFullYearSelection .MuiButtonBase-root {
  width: 100%;
  height: 50px;
  border-radius: unset;
}
.muiFullYearSelection .MuiButtonBase-root:hover {
  background-color: unset;
}
.muiFullYearSelection .MuiInputAdornment-root {
  width: 100%;
  margin-left: -45%;
}

@media only screen and (max-width: 400px) {
  .muiFullYearSelection .MuiInputBase-input {
    width: 46%;
  }
  .muiFullYearSelection .MuiInputAdornment-root {
    width: 100%;
    margin-left: -56%;
  }
}

.timePickerWrapper {
  width: 100%;
}

.timePickerWrapper label {
  font-weight: unset;
  font-size: 15px;
  line-height: 26.63px;
  float: left;
}

.scheduleTimePicker {
  min-width: unset !important;
}
.scheduleTimePicker .MuiInputBase-input {
  width: 35%;
}
.scheduleTimePicker .MuiInputBase-root {
  padding-right: unset;
  background-color: white;
}
.scheduleTimePicker .MuiSvgIcon-root {
  display: none;
}
.scheduleTimePicker .MuiInputAdornment-root {
  width: 100% !important;
  margin-left: -39%;
  margin-right: unset;
}
.scheduleTimePicker .MuiButtonBase-root {
  width: 100% !important;
  height: 50px !important;
  border-radius: unset !important;
  margin-right: unset;
}

@media only screen and (max-width: 500px) {
  .scheduleTimePicker .MuiInputBase-input {
    width: 16%;
  }
}
@media only screen and (max-width: 1199px) {
  .scheduleTimePicker .MuiInputBase-input {
    width: 40%;
  }
}
@media only screen and (max-width: 490px) {
  .scheduleTimePicker .MuiInputBase-input {
    width: 100%;
  }
}

/* Responsive video player wrapper */
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  /* border: 1px solid #aeaeae; */
  border-radius: 9px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.player-wrapper .react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #aeaeae;
}
.player-wrapper .react-player video {
  border-radius: 9px;
  border-radius: 9px;
}

/* Play button overlay */
.play-button-overlay {
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #00000040; */
  cursor: pointer;
  border-radius: 9px;
}

.play-button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  font-size: 64px;
  color: white;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button svg {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 64px;
  color: #0094f6;
  position: absolute;
}

@media only screen and (max-width: 991px) {
  .activeShow {
    align-items: unset !important;
  }

  .activeShow a {
    margin-left: 0px !important;
    margin-top: 12px;
  }

  .play-button {
    width: 44px;
    height: 44px;
  }
  .play-button svg {
    font-size: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .play-button {
    width: 33px;
    height: 33px;
  }
  .play-button svg {
    font-size: 41px;
  }
}

@media only screen and (max-width: 575px) {
  .play-button {
    width: 52px;
    height: 52px;
  }
  .play-button svg {
    font-size: 64px;
  }
}

@media only screen and (max-width: 425px) {
  .play-button {
    width: 45px;
    height: 45px;
  }
  .play-button svg {
    font-size: 56px;
  }
}

@media only screen and (max-width: 375px) {
  .play-button {
    width: 42px;
    height: 42px;
  }
  .play-button svg {
    font-size: 52px;
  }
}

.ant-picker-outlined {
  height: 45px;
}

.ant-picker-panels {
  display: block !important;
}

.ant-picker-panel-container {
  margin-left: -32px !important;
}
