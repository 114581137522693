.addCardBtn {
  width: 100%;
  padding: 10px;
}
.successInfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}
.successText {
  font-size: 30px;
  font-weight: 700;
}
.successTextDesc {
  font-size: 15px;
  font-weight: 500;
  color: #626262;
}
@media screen and (max-width: 420px) {
  .successInfo img {
    height: 150px;
    width: 200px;
  }
}
