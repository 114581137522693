.main {
  /* min-height: calc(100vh - 135px); */
  min-height: calc(100vh - 242px);
  overflow-x: hidden;
}

/* header css */
.activeShow {
  align-items: center;
}

.activeShow a {
  font-size: 20px;
  /* color: #828282; */
  color: #bbbbbb;
  font-weight: 500;
  text-decoration: none !important;
  margin-left: 30px;
}

.activeShow a.active {
  font-size: 20px;
  font-weight: 500;
  /* color: #0f7dff; */
  color: #ffffff;
  text-decoration: none !important;
  margin-left: 30px;
}

@media only screen and (max-width: 991px) {
  .activeShow {
    align-items: unset !important;
  }

  .activeShow a {
    margin-left: 0px !important;
    margin-top: 12px;
  }
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: unset !important;
}

.formSelect {
  padding-bottom: 28px;
}

.formSelect .css-1dimb5e-singleValue {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: black !important;
  /* color: #6e6e6e !important; */
}

.formSelect .css-13cymwt-control {
  border-radius: 4px !important;
  height: 50px !important;
  background-color: white;
}

.formSelect .css-1xz3in2-control {
  border-radius: 4px !important;
  height: 50px !important;
}

.formSelect .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.formSelect svg.css-tj5bde-Svg {
  content: "" !important;
  position: absolute !important;
  left: 93% !important;
  top: 40% !important;
  width: 0 !important;
  height: 0;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-top: 10px solid #828282 !important;
  clear: both !important;
}

.formSelect .css-8mmkcg {
  content: "" !important;
  position: absolute !important;
  left: 93% !important;
  top: 40% !important;
  width: 0 !important;
  height: 0;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-top: 10px solid #828282 !important;
  clear: both !important;
}

@media only screen and (max-width: 575px) {
  .formSelect .css-s5xyum-Placeholder {
    font-size: 15px !important;
    top: 6px !important;
  }

  .formSelect .css-1dimb5e-singleValue {
    font-size: 15px !important;
  }

  .formSelect svg.css-tj5bde-Svg {
    content: "" !important;
    position: absolute !important;
    left: 92% !important;
    top: 40% !important;
    width: 0 !important;
    height: 0;
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-top: 8px solid #828282 !important;
    clear: both !important;
  }

  .formSelect .css-8mmkcg {
    content: "" !important;
    position: absolute !important;
    left: 92% !important;
    top: 40% !important;
    width: 0 !important;
    height: 0;
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-top: 8px solid #828282 !important;
    clear: both !important;
  }

  .customBox span {
    font-size: 15px !important;
  }

  .customBox svg {
    font-size: 20px !important;
  }
}

.customBox:hover {
  background-color: #f2f2f2 !important;
}

.errorMsg {
  color: #ff0000;
  font-weight: 400;
  font-size: 14px;
}

.setIconPassword {
  position: relative;
}

.passwordicon {
  right: 13px;
  top: 43px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
  color: #afaeae;
}

.formTopSpace {
  padding-top: 40px;
}

.formSection label {
  color: #202020;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
}

.formOrderSection {
  margin: 8px 0px;
}

.formOrderSection .form-control {
  height: 50px;
  color: #584d4d;
}

.formOrderSection label {
  font-weight: 600;
  font-size: 22px;
  line-height: 26.63px;
  float: left;
}

.formOrderSection p {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  height: 50px;
  color: #584d4d;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  cursor: pointer;
}

.selectModal .floorCheckboxes {
  display: flex;
  justify-content: center;
  margin: 14px 0px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  /* padding: 4px; */
  background: #dcdcdc;
  color: black;
  height: 40px;
  line-height: 41px;
  border-radius: 4px;
}

.selectModal .categoryCheckboxes {
  display: flex;
  justify-content: center;
  margin: 8px 0px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: black;
  background: #dcdcdc;
  height: 40px;
  line-height: 41px;
  border-radius: 4px;
  width: 100%;
  padding: 0px;
}

.selectModal label {
  cursor: pointer;
}

.selectModal .modal-title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}

.selectModal .modal-header svg {
  font-size: 30px;
  color: #0f7dff;
  cursor: pointer;
}

.selectModal .modal-body {
  background-color: #fafafa;
  border-radius: 8px;
}

.orderSection {
  position: relative;
}

.orderSectionLable {
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 26.63px;
  margin-bottom: 8px;
}

.liftSelection .form-check {
  display: flex;
  align-items: center;
  gap: 8px;
}

.liftSelection .form-check-input {
  margin-top: 0px;
  padding: 8px;
}

.orderSection svg {
  position: absolute;
  top: 50px;
  right: 13px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
  cursor: pointer;
}

.propertySection {
  position: relative;
  /* margin-top: 42px; */
}

.propertySection svg {
  position: absolute;
  top: 15px;
  right: 13px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
  cursor: pointer;
}
.contactInfo {
  position: relative;
  margin: unset;
}
.contactInfo svg {
  position: absolute;
  top: 15px;
  right: 22px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
  cursor: pointer;
}
/* .formSection input {
  color: #7a7a7a;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  height: 50px !important;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  border: 1px solid #bfbfbf;
  background: #ffffff;
}

.formSection input.form-control:focus {
  border: 1px solid #bfbfbf !important;
} */

@media only screen and (min-width: 1199px) {
  .colSpace {
    padding: 0px 30px;
  }
}

/* phone input css */

.phoneInputSet .form-control {
  color: #7a7a7a !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
  height: 56px !important;
  box-shadow: 0px 3px 12px 0px #ffffff33 !important;
  border: 1px solid #bfbfbf !important;
  background: #ffffff !important;
  width: 100% !important;
}

.phoneInputSet .react-tel-input .flag-dropdown {
  border-right: none !important;
  background: #ffffff !important;
}

/* otp input css */

.otpbox input::placeholder {
  font-size: 25px;
  color: #878787;
}

.otpInput {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.inputStyle {
  width: 56px !important;
  height: 40px !important;
  margin: 0 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.inputStyle:focus-visible {
  box-shadow: none !important;
  outline: none;
}

@media only screen and (max-width: 400px) {
  .inputStyle {
    width: 45px !important;
    height: 40px !important;
    margin: 0 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.otpError {
  text-align: center;
  margin-top: 7px;
  /* margin-bottom: 50px; */
}

.backarrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}

.backarrow svg {
  box-shadow: 0px 0px 4px 0px #00000033;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
}

.socialbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* facebook social login css */
.SocialLoginBtn {
  margin-right: 20px;
}

.SocialLoginBtn button.kep-login-facebook.metro {
  background: unset !important;
  border: unset !important;
  padding: 0px !important;
}

/* all over mid section */
.midSection {
  padding: 25px 0px;
}

.commonButton button {
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  height: 56px;
  width: 100%;
  border-radius: var(--bs-border-radius);
}

.stopsField {
  position: relative;
}
.stopsField svg {
  position: absolute;
  top: 29px;
  right: -3px;
  font-size: 25px;
  color: rgb(218 105 59);
  cursor: pointer;
}

.stopsField input {
  color: black !important;
  font-weight: 500;
}

.stopsField input::placeholder {
  color: #584d4d;
  font-weight: 400;
}

.thirdTab {
  position: relative;
  height: 50px !important;
}
.thirdTab p {
  height: 50px !important;
}
.thirdTab .form-control {
  position: relative;
  height: 50px !important;
}
.thirdTab svg {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}
.fifthTab {
  position: relative;
}
.fifthTab svg {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}
.timePicker .MuiFormControl-root {
  width: 100%;
}
.timePicker .MuiFormControl-root .MuiSvgIcon-root {
  top: 0px;
}
.timePicker label {
  font-weight: unset;
  font-size: 15px;
  line-height: 26.63px;
  float: left;
}

.fifthTab p {
  background-color: #f9f9f9;
  height: 50px;
  margin-bottom: 0px;
}
.form-check-input {
  cursor: pointer;
}
.MuiPickersCalendarHeader-label {
  font-weight: bold;
}
.MuiPickersArrowSwitcher-root .css-1vooibu-MuiSvgIcon-root {
  background-color: #0f7dff;
  color: white;
}
.MuiTypography-root {
  font-weight: bold !important;
  color: black !important;
}
.MuiButtonBase-root {
  color: #777777 !important;
  font-weight: bold !important;
}
.Mui-selected {
  color: white !important;
}
.MuiTextField-root {
  background-color: #f7f7f7;
}

@media only screen and (max-width: 370px) {
  .MuiDateCalendar-root {
    width: 250px !important;
  }
}

.liftSelection {
  margin-top: -14px;
}

.addCard input {
  height: 50px;
}

.form-control.is-invalid {
  background-image: none;
}

.firstTabForm .invalid-feedback {
  margin-top: 0px;
  margin-bottom: -29px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
}

.firstTabForm .form-control.is-invalid {
  border-color: transparent;
}

@media only screen and (min-width: 992px) {
  .spaceTopManage {
    margin-top: 74px !important;
  }
}

.liftSelection .form-check-input {
  background-color: transparent !important;
  border-color: white !important;
  border-radius: 0% !important;
}

.validateError {
  color: rgba(255, 0, 0, 0.877);
  font-size: 14px;
  margin-top: 4px;
}

/* .leaflet-top {
  z-index: 400 !important;
} */

.packing_switch .form-check-input {
  height: 20px;
  width: 40px;
}

/* confirm alert modal css */
.confirmBox {
  background: #0f7dff;
  height: 70px;
}
.confirmIcon {
  text-align: center;
  border: 2px solid #0094f6;
  border-image-source: linear-gradient(180deg, #0094f6 0%, #2fc2fe 100%),
    linear-gradient(0deg, #ec3b1e, #ec3b1e);
  border-radius: 50%;
  width: 100%;
  max-width: 85px;
  height: 85px;
  margin: 0px auto;
  margin-top: -62px;
  background: #ffffff;
}

.confirmIcon svg {
  color: #ec3b1e;
  margin: 20px auto;
  font-size: 40px;
}

.confirmIconMsg {
  text-align: center;
  margin: 20px auto;
  width: 100%;
}

.confirmIconMsg h4 {
  color: #202020;
  font-size: 26px;
  font-weight: 600;
  line-height: 42.36px;
}

.confirmIconMsg p {
  color: #555555;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.41px;
}

.alertButton button.confirmLeftButton {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: #0f7dff;
  height: 40px;
  width: 100%;
  border-radius: 45px;
}
/* show setting active class */
a.userActive {
  text-decoration: none !important;
}

a.userActive.active p {
  color: #0f7dff !important;
}

a.userActive.active svg {
  color: #0f7dff !important;
}

.couponModal .modal-body {
  max-height: 510px;
  overflow: auto;
}

.stripeFormContainer {
  padding: 22px;
}

.invalid-feedback {
  text-align: left;
}

.card-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.input-group {
  /* margin-bottom: 20px; */
  margin-bottom: 28px;
  flex-wrap: unset;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.card-element-container {
  margin-bottom: 20px;
  font-family: "Source Code Pro", monospace;
}

.StripeElement {
  height: 50px;
  padding: 16px 12px;
  border: 1px solid #ccc;
  border-radius: 4px 4px 4px 4px !important;
  background-color: white;
}

.StripeElement--focus {
  border-color: #4d90fe;
  box-shadow: 0 0 0 0.2em rgba(29, 155, 209, 0.25);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.jobSearchSection .form-control:disabled {
  background-color: unset;
}

/* jobs tab section css */
ul.jobTabs.nav.nav-tabs {
  border: 2px solid #f6f6f6 !important;
  border-radius: 48px !important;
  margin: 20px 0px 25px;
}

.jobTabs.nav-tabs .nav-link {
  border: unset !important;
}

.jobTabs.nav-tabs .nav-link {
  background-color: transparent !important;
  color: #555555 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
}

.jobTabs li.nav-item {
  width: 50%;
}

.jobTabs.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.jobTabs.nav-tabs .nav-link.active {
  background: linear-gradient(45deg, #0f7dff, #6931df);
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0em;
  color: #ffffff !important;
  border-radius: 48px;
  width: 100%;
  margin: 2px;
}

@media only screen and (max-width: 400px) {
  .jobTabs.nav-tabs .nav-link.active {
    font-size: 14px !important;
  }

  .jobTabs.nav-tabs .nav-link {
    font-size: 14px !important;
  }
}

.jobDatepickerfull {
  width: 100% !important;
}

.jobDetails {
  overflow: hidden;
}

.trackJobStepper .MuiStepLabel-iconContainer img {
  width: 26px;
}

@media only screen and (max-width: 400px) {
  .trackJobStepper .MuiStepLabel-iconContainer svg {
    width: 18px;
  }
  .trackJobStepper .MuiStepLabel-iconContainer img {
    width: 18px;
  }
  .trackJobStepper .MuiStepLabel-iconContainer div {
    height: 35px;
    width: 35px;
  }
  .trackJobStepper .MuiStepConnector-line {
    margin-top: -6px;
  }
  .trackJobStepper .MuiStepConnector-root {
    left: calc(-48% + 16px);
    right: calc(52% + 16px);
  }
}

@media only screen and (max-width: 991px) {
  .moveSideContent {
    width: 100%;
    margin-left: 210px;
    transition: margin-left 0.5s ease;
  }
}

.sideBarAccordian .accordion-button:not(.collapsed) {
  font-weight: 600;
}

.customTimePicker .MuiInputBase-input {
  width: 14%;
}
.customTimePicker .MuiInputBase-root {
  padding-right: unset;
}
.customTimePicker .MuiSvgIcon-root {
  display: none;
}
.customTimePicker .MuiInputAdornment-root {
  width: 100% !important;
  margin-left: -17%;
  margin-right: unset;
}
.customTimePicker .MuiButtonBase-root {
  width: 100% !important;
  height: 50px !important;
  border-radius: unset !important;
  margin-right: unset;
}

@media only screen and (max-width: 500px) {
  .customTimePicker .MuiInputBase-input {
    width: 16%;
  }
}

.filterjobDatepickerfull {
  width: 100% !important;
  background-color: white;
}
.filterjobDatepickerfull .MuiInputBase-root {
  height: 45px;
}

/* ----support--- */
.formSectionSupport input {
  color: #000 !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 16.94px;
  height: 56px !important;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  background: #f9f9f9;
  border-radius: 5px;
}

.formSectionSupport input.form-control:focus {
  border: 0.3px solid #dcdcdc;
}

.formSectionSupport input::placeholder {
  font-size: 16px;
  font-weight: 300;
  line-height: 19.36px;
  text-align: left;
  color: #555555;
}

.formSectionSupport textarea.form-control:focus {
  border: 0.3px solid #dcdcdc !important;
}

.formSectionSupport textarea.form-control {
  background: #f9f9f9;
  border-radius: 5px;
}

.formSectionSupport textarea.form-control::placeholder {
  font-size: 16px;
  font-weight: 300;
  line-height: 19.36px;
  text-align: left;
  color: #555555;
  padding: 5px 0px;
}

.profileHeadingBotLine::after {
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  margin-top: 4px;
  padding-top: 1px;
  border-radius: 3px;
  border-bottom: 2px solid transparent;
  background: #0f7dff;
}

.notify_toast_firebase {
  z-index: 99999 !important;
  position: fixed;
  background-color: whitesmoke;
  cursor: pointer;
}

.popover {
  position: absolute;
  /* inset: 0px auto auto 0px; */
  transform: none;
  z-index: 999;
}

.popover-arrow {
  left: -7px;
}

.popover-body {
  position: absolute;
  background-color: white;
  left: -293px;
  top: 0;
  width: 330px;
  height: 270px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.customSelectPlaceholder {
  font-size: 20px; /* Default font size */
  /* font-weight: 500; */
  font-weight: 400;
  /* color: #6e6e6e; */
  color: #8f8f8f;
  /* position: absolute; */
  top: 0px;
  left: 15px;
  font-family: "Inter";
  background-color: white;
}

@media only screen and (max-width: 575px) {
  .customSelectPlaceholder {
    font-size: 15px; /* Smaller font size for smaller screens */
  }
  .addressFieldHome input::placeholder {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 350px) {
  .customSelectPlaceholder {
    font-size: 14px; /* Smaller font size for smaller screens */
  }
}

.trackJobStepper2 .MuiStack-root {
  width: unset !important;
}

.trackJobStepper2 .MuiStepConnector-root {
  margin-left: 22px;
}

.faqAccordian .accordion-button:not(.collapsed) {
  color: white;
  font-weight: 600;
  background: linear-gradient(88.85deg, #6931df 0.73%, #4770eb 99.01%);
}

/* new css for manage collection address */
.collectAddress {
  margin: 8px 0px;
  position: relative;
  text-align: left;
}

.collectAddress svg {
  position: absolute;
  top: 46px;
  left: -34px;
}

.collectAddress .form-control {
  height: 50px;
  color: #584d4d;
  /* margin-top: 5px; */
}

.collectAddress input::placeholder {
  color: #584d4d;
  font-weight: 400;
}

.collectAddress input {
  color: black !important;
  font-weight: 500;
}

.addressFieldHome input {
  color: black !important;
  font-weight: 500;
}

.addressFieldHome input::placeholder {
  color: #8f8f8f !important;
  font-size: 20px;
  font-weight: 400;
  font-family: "Inter";
}

.muiFullYearSelection .Mui-disabled {
  color: rgba(0, 0, 0, 0.3) !important; /* Grey out the text */
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ) !important; /* Light background for disabled dates */
  cursor: not-allowed; /* Show a not-allowed cursor */
}

.pac-container {
  z-index: 9999 !important;
}

.boldBlackValue {
  color: black !important;
  font-weight: 500;
}

.ratingPointsSection .slick-slider .slick-list {
  text-align: center;
}
.ratingPointsSection .MuiRating-root {
  color: red;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  border-radius: 10px;
  cursor: pointer;
}

.custom-radio {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-radio:checked {
  border: 2px solid white;
}

.custom-radio:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.custom-radio:hover {
  border-color: white;
}
