.formBlock {
  max-width: 800px;
  border-radius: 10px;
  margin: 0 auto;
  background: #ffffff;
  padding: 10px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

.signUpText h3 {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  line-height: 49px;
  text-align: center;
}

.loginBtn button {
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  height: 56px;
  width: 100%;
  border-radius: 1px;
  margin-top: 25px;
  border-radius: var(--bs-border-radius);
}

.bottomText {
  text-align: center;
  color: #626262;
  font-weight: 500;
  font-size: 15px;
  line-height: 18.15px;
  margin-top: 20px;
  margin-bottom: 0px !important;
}

.bottomText a {
  text-decoration: none !important;
  color: #0a3cd1;
  font-weight: 500;
}

/* @media only screen and (min-width: 1199px) {
  .signUpForm {
    padding: 0px 70px;
  }
} */

@media only screen and (max-width: 575px) {
  .signUpText h3 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 850px) {
  .formBlock {
    margin: 0px 20px;
  }
}
