.topHeadSummmary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f7dff;
  color: white;
  padding: 0px 90px 0px 90px;
}
.topHeadBackBtn {
  padding: 12px 0px;
}
.topHeadBackBtn svg {
  background-color: white;
  color: black;
  border-radius: 50%;
  font-size: 40px;
  padding: 8px;
  cursor: pointer;
}
.topTitle {
  font-weight: 600;
  font-size: 20px;
}
.rightQuantity {
  margin-right: 14px;
}
.mainContainerWrapper {
  background-color: #f8f8f8;
}
.mainContainer {
  padding: 22px 90px 22px 90px;
}
.subContainer {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 6px;
}
.titleName {
  font-weight: 600;
  font-size: 18px;
}
.titleNameDimension {
  font-weight: 600;
  font-size: 18px;
  /* margin-bottom: 8px; */
}
.tabCount {
  color: #828282;
}
.ownQuantity {
  /* margin-top: 8px; */
}
.subTitleName {
  font-weight: 500;
  font-size: 16px;
}
.stopTitle {
  font-weight: 500;
  font-size: 14px;
}
.titleDescription {
  font-weight: 300;
  font-size: 14px;
}
.floorSection {
  display: flex;
  gap: 20px;
}
.floorSectionTitle {
  font-size: 12px;
  font-weight: 500;
}
.floorValue {
  color: #0f7dff;
  font-size: 12px;
}

.liftCheckYes {
  color: #0f7dff;
  font-size: 12px;
}

.liftCheckNo {
  color: red;
  font-size: 12px;
}
.itemsSection {
  display: flex;
  justify-content: space-between;
}
/* .ownItemsSection {
    display: flex;
  } */

.itemsSubSection {
  display: flex;
}
.furnitureName {
  font-size: 20px;
  font-weight: 600;
  color: #0f7dff;
}
.dimensionType {
  font-size: 14px;
  font-weight: 600;
  color: #626262;
}
.dimensionValue {
  font-size: 14px;
  font-weight: 600;
}
.titleHeading {
  font-weight: 600;
  font-size: 18px;
  margin: 18px 0px 4px 0px;
}

.ownTitleHeading {
  font-weight: 600;
  font-size: 18px;
  margin: 0px 0px 4px 0px;
}

.furnitureContainer {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
}

.furnitureContainerSlider {
  background-color: white;
  padding: 16px 40px;
  border-radius: 6px;
  display: flex !important;
  flex-direction: column;
  gap: 6px;
}
.singleFurnitureContainerSlider {
  background-color: white;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.vehicleType {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicleImage {
  height: 60px;
  width: 90px;
  object-fit: contain;
}

.vehicleContainer {
  background-color: white;
  padding: 4px 16px 4px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
}

.isHelper {
  display: flex;
  font-size: 15px;
  font-weight: 500;
  gap: 6px;
}
.verticalDivider {
  border-left: 1px solid grey;
  margin: 5px;
}
.availabilityContainer {
  margin-top: 12px;
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}
.availabilityDay {
  color: #0f7dff;
  font-size: 15px;
  font-weight: 500;
}
.estimatedTime {
  font-size: 15px;
  font-weight: 400;
  margin-top: 18px;
}
.estimatedTime span {
  color: #3159d2;
  font-weight: 500;
}
.bottomBtnEdit {
  font-weight: 600;
  width: 100%;
  padding: 12px;
}
.bottomBtnPrice {
  font-weight: 600;
  width: 100%;
  background-color: transparent;
  color: #0f7dff;
  padding: 12px;
}
.chatFeature {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: -44px -68px 0px 0px;
}

@media screen and (max-width: 550px) {
  .mainContainer {
    padding: 22px 60px 22px 60px;
  }
  .chatFeature {
    margin: -44px -52px 0px 0px;
  }
  .topHeadSummmary {
    padding: 0px 60px 0px 60px;
  }
}

@media screen and (max-width: 480px) {
  .mainContainer {
    padding: 22px;
  }
  .chatFeature {
    margin: 20px -12px 0px 0px;
  }
  .topHeadSummmary {
    padding: 0px 22px 0px 22px;
  }
}
.PrevSlickArrow {
  position: absolute;
  bottom: 42px;
  left: 3px;
  z-index: 1;
}

.NextSlickArrow {
  position: absolute;
  bottom: 42px;
  right: 3px;
  z-index: 1;
}

.PrevSlickArrowCustom {
  position: absolute;
  bottom: 104px;
  left: 3px;
  z-index: 1;
}

.NextSlickArrowCustom {
  position: absolute;
  bottom: 104px;
  right: 3px;
  z-index: 1;
}

.couponSection {
  margin-bottom: 28px;
}

.selectedCouponView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  height: 50px;
  padding: 16px;
}

.selectedCode {
  font-size: 13px;
  font-weight: 700;
  color: #4770eb;
}

.selectedPercent {
  font-size: 13px;
  font-weight: 700;
  color: #202020;
}

.selectedCoupon {
  display: flex;
  align-items: center;
  gap: 12px;
}

.selectedCoupon svg {
  cursor: pointer;
}

.amountWrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 12px;
  margin: 18px 0px 18px 0px;
  border-radius: 6px;
}
.amountInfo {
  display: flex;
  justify-content: space-between;
}
.discountPrice {
  color: red;
}
.amountInfoTotal {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.trackOrderBtn {
  display: flex;
  justify-content: flex-end;
}
.pendingStatus {
  display: flex;
  justify-content: center;
  background-color: #f1e5d5;
  color: #d3770a;
  padding: 12px;
  border-radius: 6px;
  font-weight: 600;
}
.driverInfo {
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
}
.mainDriverProfile {
  background-color: white;
  margin: 2px 0px 12px 0px;
  padding: 12px;
  border-radius: 6px;
  gap: 6px;
}

.showInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.driverProfile {
  display: flex;
  align-items: center;
  gap: 16px;
}
.driverImage img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}
.driverName {
  font-size: 15px;
  font-weight: 600;
}
.driverEmail {
  font-size: 12px;
  font-weight: 400;
  color: #626262;
  word-break: break-all;
}
.driverAmount {
  color: #4770eb;
  font-size: 16px;
  font-weight: 600;
}
.pickUpImage {
  border-radius: 4px;
  height: 80px;
  width: 80px;
  cursor: pointer;
  object-fit: contain;
}
.imageWrapper {
}
.pickUpTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.reviewSection {
  background-color: white;
  border-radius: 6px;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
}

.reviewSectionSub {
  display: flex;
  justify-content: space-between;
}

.reviewSection span {
  gap: 12px;
  font-size: 30px;
}
.reviewText {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.ratingCount {
  color: #3159d2;
  font-size: 20px;
  font-weight: 600;
}
.footerSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 12px;
  width: 100%;
}
.completedTag {
  width: 50%;
  color: #058321;
  background-color: #deefe2;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.completedTag span {
  padding: 8px 0px;
  font-size: 16px;
  font-weight: 600;
}
.nextBtn {
  padding: 6px 32px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(to left, #0f7dff, #6931df);
  cursor: pointer;
}
.nextBtn span {
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(to right, #6931df, #0f7dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nextBtn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background-color: white;
  z-index: -1;
  transition: 200ms;
}

@media screen and (max-width: 420px) {
  .nextBtn {
    padding: 6px 8px;
  }
  .availabilityDay {
    font-size: 13px;
  }
  .driverAmount {
    font-size: 14px;
  }
}

.addressRouteWrapper {
  display: flex;
  gap: 12px;
}
.subLocationContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.leftRouteLine {
  border-left: 1px dashed #a5a5a5;
  width: 2px;
  height: 100%;
}
.routeLinePath {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}
.hrLineDraw {
  background-color: #d9d9d9;
  height: 1px;
}
.hrLineStops {
  background-color: #d9d9d9;
  height: 1px;
  width: 50%;
}
.cancelJobSection {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.cancelJobSection span {
  cursor: pointer;
  color: #ec3b1e;
  font-size: 18px;
  font-weight: 600;
}

.cancelledJob {
  display: flex;
  justify-content: flex-end;
}

.cancelledJob span {
  display: flex;
  justify-content: center;
  background-color: #f5e0db;
  color: #e82100;
  width: 50%;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
}

.totalMileage {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.totalMileage span {
  color: #0f7dff;
}

.rejectDriver p {
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 0px;
}
.topDimensionWrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

@media screen and (max-width: 570px) {
  .dimensionType {
    font-size: 12px;
  }
  .dimensionValue {
    font-size: 12px;
  }

  .cancelledJob span {
    width: 100%;
  }
}
.imageWrapper {
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 770px) {
  .pickUpImage {
    height: 50px;
    width: 50px;
  }
  .imageWrapper {
    overflow: scroll;
  }
}
.refundedText {
  display: flex;
  justify-content: flex-end;
  color: red;
}
.newLocationSection {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}

.newLocationSection span {
  color: #0f7dff;
}
