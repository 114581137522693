.topHead {
  display: flex;
  align-items: center;
  margin: 0px 80px;
}
.headTitle {
  font-size: 22px;
  font-weight: 500;
}
.topContainerWrapper {
  background-color: #f8f8f8;
  padding: 24px 80px;
}
.cardContainer {
  display: flex;
  justify-content: space-around;
  gap: 12px;
  /* background-color: white; */
  /* border: 1px solid #ededed; */
  border-radius: 6px;
  padding: 40px 0px 0px 0px;
}
.cardBlock {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 22px;
}
.cardImage img {
  height: 180px;
  width: 340px;
}
.paymentDetails {
  font-size: 24px;
  font-weight: 600;
  margin: 22px 0px;
}
.amountFeeContainer {
  /* padding: 0px 22px; */
}
.amountFee {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}
.amountSection {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.amountValueSection {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-end;
}
.discountValue {
  color: red;
}
.totalAmountFee {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
}
.footerBtnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin: 28px 0px;
}

.addCardBtn {
  padding: 8px 60px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(to left, #0f7dff, #6931df);
  cursor: pointer;
}

.addCardBtn span {
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(to right, #6931df, #0f7dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.addCardBtn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background-color: white;
  z-index: -1;
  transition: 200ms;
}

.paymentBtn {
  padding: 8px 80px;
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 650px) {
  .addCardBtn {
    padding: 6px 12px;
  }
  .paymentBtn {
    padding: 6px 42px;
  }
  .topContainerWrapper {
    padding: 12px 20px;
  }
}
@media screen and (max-width: 560px) {
  .topHead {
    margin: 0px 20px;
  }
  .cardImage img {
    height: 120px;
    width: 240px;
  }
}
@media screen and (max-width: 420px) {
  .cardImage img {
    height: 110px;
    width: 200px;
  }
  /* .topContainerWrapper {
    padding: 12px 20px;
  } */
  .cardWrapper {
    width: 260px !important;
  }
  .cardDetails {
    gap: 16px !important;
  }
}

.cardWrapper {
  width: 350px;
  border: 1px solid #ccc;
  /* background-color: #4770eb; */
  /* background: linear-gradient(90deg, #4770eb, #81a0ff); */
  background: linear-gradient(to right, #4770eb, #81a0ff);
  color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}

.crossIcon {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.cardScannerImg img {
  width: 38px;
}

.cardDetails {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cardNumber {
  font-size: 15px;
  font-weight: 500;
  color: #c7d1ee;
}

.cardNumberValue {
  font-size: 15px;
  font-weight: 500;
}

.cardExpiry {
  font-size: 13px;
  font-weight: 500;
  color: #c7d1ee;
}

.cardExpiryValue {
  font-size: 13px;
  font-weight: 500;
}

.titleName {
  font-weight: 600;
  font-size: 22px;
  word-break: break-all;
}

.titleHeading {
  font-weight: 600;
  font-size: 18px;
  margin: 0px 0px 6px 0px;
}

.furnitureContainer {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
  border: 0.3px solid #e9e9e9;
}

.availabilityContainer {
  margin-top: 12px;
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 0.3px solid #e9e9e9;
}

.vehicleVanType {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vehicleImage {
  height: 80px;
  width: 120px;
  object-fit: contain;
}

.newCardText {
  font-size: 18px;
  font-weight: 600;
  margin: 12px 0px;
  margin-top: 22px;
}

.paymentSection {
  padding: 18px 22px;
  background-color: white;
  border-radius: 5px;
}

.fieldLabel {
  font-size: 14px;
  font-weight: 500;
}
.cardTypeSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.cardTypeLogo {
  display: flex;
  gap: 12px;
  margin-top: 12px;
}

@media screen and (max-width: 420px) {
  .cardTypeLogo img {
    width: 50px;
  }
}

@media screen and (max-width: 991px) {
  .cardTypeSection {
    gap: 12px;
  }
}
