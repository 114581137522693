.applyBtn {
  font-weight: 600;
  margin-top: 16px;
  height: 45px;
}

.clearBtn {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  font-weight: 600;
}

.clearBtn span {
  cursor: pointer;
}

.dateSelector {
  margin-bottom: 22px;
  margin: 12px 0px;
}

.categoryHead {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  text-align: center;
}

.activeCategory {
  color: white;
  background-color: #0f7dff;
  width: 100%;
  text-align: center;
  height: 40px;
  border-radius: 4px;
}

.activeFloor {
  color: white;
  background: #0f7dff;
  width: 100%;
  text-align: center;
  height: 40px;
  border-radius: 4px;
}

/* Rating modal css */

.ratingSection {
  display: flex;
  justify-content: center;
  padding: 12px 0px;
}

.ratingSection span {
  gap: 12px;
  font-size: 45px;
}

.descriptionSection {
  margin: 12px 12px 0px 12px;
}

.sumbmitBtnSection {
  margin: 0px 12px;
}
.categoryRow {
  margin: 14px;
}
.btnApply {
  margin: 12px 0px;
}

/* Rating success modal css */

.successInfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}
.successText {
  font-size: 30px;
  font-weight: 700;
}
.successTextDesc {
  font-size: 15px;
  font-weight: 500;
  color: #626262;
  margin: 0px 12px;
}
.addCardBtn {
  width: 100%;
  padding: 10px;
}
