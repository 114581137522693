.dateSelector {
  margin: 12px 0px;
}
.locationInput input {
  height: 45px;
}
.distanceSection {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 6px;
}
.distanceHead {
  font-size: 16px;
  font-weight: 600;
}
.distanceValue {
  font-size: 14px;
  font-weight: 600;
  color: #0094f6;
}
.applyBtn {
  font-weight: 600;
  /* margin-top: 32px; */
  background-color: #0f7dff;
  border: unset;
}
.btnApply {
  margin: 12px 0px;
}
.clearBtn {
  display: flex;
  justify-content: center;
  margin-top: 6px;
  font-weight: 600;
}

.clearBtn span {
  cursor: pointer;
}

.categoryHead {
  font-size: 20px;
  font-weight: 600;
  margin-top: 22px;
  color: #333333;
  text-align: center;
}

.activeCategory {
  color: white;
  background-color: #2fc2fe;
  width: 100%;
  text-align: center;
  height: 40px;
  border-radius: 4px;
}

.categoryRow {
  margin: 14px;
}

.distanceSectionRow {
  /* margin: 0px 14px; */
}

/* Rating modal css */

.ratingSection {
  display: flex;
  justify-content: center;
  padding: 12px 0px;
}

.ratingSection span {
  gap: 12px;
  font-size: 45px;
}

.descriptionSection {
  margin: 12px 12px 0px 12px;
}

.sumbmitBtnSection {
  margin: 0px 12px;
}
