.NotificationUnread {
  background-color: rgba(240, 240, 240, 0.47);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
  cursor: pointer;
}

.NotificationRead {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
  cursor: pointer;
}

.NotificationRead h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}

.NotificationRead h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.NotificationRead p {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
}

.NotificationUnread h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}

.NotificationUnread h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.NotificationUnread p {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
}

.notificationImg {
  width: 29px;
  height: 30px;
  color: #515151;
  cursor: pointer;
}

.topIcon {
  display: flex;
  align-items: center;
}

.notificationSection {
  position: relative;
}

.notificationCount {
  position: absolute;
  right: 4px;
  top: 0px;
  background: #ec3b1e;
  border-radius: 50%;
  padding: 5px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}

.clearBtn .bottomBtnEdit {
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: linear-gradient(0deg, #0f7dff, #0f7dff),
    linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
  height: 30px;
  width: 100%;
  max-width: 80px;
  border-radius: var(--bs-border-radius);
}

.clearBtn {
  margin-top: 15px;
  display: flex;
  justify-content: end;
}
.noResultsMessage {
  text-align: center;
}
